import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { PagedDataResponse$1, ShippingOrderResponse, OrderStatusChangedResponse_$reflection, ApiMessageResponse_$reflection, ShippingOrderDetailResponse_$reflection, PagedDataResponse$1_$reflection, ShippingOrderResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Deferred_map, Cmd_resetForm, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { string_type, union_type, record_type, unit_type, option_type, class_type, int32_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Default_pagedData, ExpandableItems$1, DataSourceItem_$reflection, ExpandableItems$1_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { collect, empty as empty_1, map as map_1, singleton as singleton_1, append, delay, toList, exists } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { defaultArg, toArray } from "../fable_modules/fable-library.4.10.0/Option.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "../SignalR.fs.js";
import { containsKey, remove, add, tryFind as tryFind_1, empty } from "../fable_modules/fable-library.4.10.0/Map.js";
import { createObj, int32ToString, equals, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { tryFind, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { orderStatuses } from "../API/DataSourceAPI.fs.js";
import { Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { downloadTemplate, getDetails as getDetails_1, search as search_1 } from "../API/ShippingOrderAPI.fs.js";
import { map, tryFind as tryFind_2 } from "../fable_modules/fable-library.4.10.0/Array.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { upload as upload_1 } from "../API/OrderAPI.fs.js";
import { FileType } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { formatStatusDescriptionAsClass, formatOrderStatusAsClass, formatShipToAddress, formatBillToAddress, formatDateTime } from "../Domain/Formatter.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(PagedDataDeferred, DetailsDeferred, Details, CurrentExpandedItemId, Page, SelectedOrderStatus, UploadFile, DownloadFileDeferred, UploadFileDeferred, SignalRHub) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.DetailsDeferred = DetailsDeferred;
        this.Details = Details;
        this.CurrentExpandedItemId = CurrentExpandedItemId;
        this.Page = (Page | 0);
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.UploadFile = UploadFile;
        this.DownloadFileDeferred = DownloadFileDeferred;
        this.UploadFileDeferred = UploadFileDeferred;
        this.SignalRHub = SignalRHub;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.ShippingOrderUpload.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(ShippingOrderResponse_$reflection()))], ["DetailsDeferred", Deferred$1_$reflection(array_type(ShippingOrderDetailResponse_$reflection()))], ["Details", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, ExpandableItems$1_$reflection(ShippingOrderDetailResponse_$reflection())])], ["CurrentExpandedItemId", option_type(int32_type)], ["Page", int32_type], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["UploadFile", option_type(class_type("Browser.Types.File", void 0))], ["DownloadFileDeferred", Deferred$1_$reflection(unit_type)], ["UploadFileDeferred", Deferred$1_$reflection(unit_type)], ["SignalRHub", class_type("SignalR.IHubConnection")]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SearchAsync", "GetDetailsAsync", "DownloadFileAsync", "UploadFileAsync", "SetNextPage", "SetPreviousPage", "SetOrderStatus", "SetUploadFile", "ToggleDetails", "OrderStatusChanged"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.ShippingOrderUpload.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(ShippingOrderResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(ShippingOrderResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["id", int32_type], ["Item2", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ShippingOrderDetailResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ShippingOrderDetailResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [], [], [["orderStatus", string_type]], [["Item", class_type("Browser.Types.File", void 0)]], [["id", int32_type]], [["Item", OrderStatusChangedResponse_$reflection()]]]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function isCurrentExpandedItem(state, itemId) {
    let input_1;
    const element = document.getElementById(`shipping-order-${itemId}`);
    if (element == null) {
        return false;
    }
    else {
        return exists((id_1) => (id_1 === element.id), toArray((input_1 = state.CurrentExpandedItemId, (input_1 == null) ? void 0 : (`shipping-order-${input_1}`))));
    }
}

export function init() {
    let signalR;
    const patternInput = register("OrderStatusChanged", (Item) => (new Msg(9, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    return [new State(new Deferred$1(0, []), new Deferred$1(0, []), empty({
        Compare: comparePrimitives,
    }), void 0, 1, void 0, void 0, new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0]), Cmd_batch(ofArray([patternInput[1], singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let input_1, value_1, input_3, input_9, res_1, input_13, input_17;
    switch (msg.tag) {
        case 4:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page + 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 5:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page - 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 7:
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, msg.fields[0], state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_2) => {
                dispatch_2(new Msg(3, [new AsyncMsg$1(0, [])]));
            })];
        case 6:
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, 1, tryFind((x_1) => (x_1.Id === msg.fields[0]), orderStatuses), state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 8: {
            const toggle = defaultArg((input_1 = tryFind_1(msg.fields[0], state.Details), (input_1 == null) ? void 0 : input_1.IsExpanded), false);
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, add(msg.fields[0], (value_1 = (new ExpandableItems$1(false, new Array(0))), defaultArg((input_3 = tryFind_1(msg.fields[0], state.Details), (input_3 == null) ? void 0 : (new ExpandableItems$1(!toggle, input_3.Items))), value_1)), remove(msg.fields[0], state.Details)), state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
        }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_7 = msg.fields[0].fields[0];
                    command_1 = ((input_7.tag === 1) ? Alert_error(Alert_title("Search Shipping Orders", Alert_texts(ofArray(input_7.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_9 = msg.fields[0].fields[0], (input_9.tag === 1) ? Default_pagedData() : input_9.fields[0])]), state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        let orderStatus;
                        const input_5 = state.SelectedOrderStatus;
                        orderStatus = ((input_5 == null) ? void 0 : input_5.DisplayValue);
                        return [new State(new Deferred$1(1, []), state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_3) => {
                            Cmd_OfAsync_start(x_3);
                        }, () => search_1(orderStatus, state.Page), void 0, (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[1].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_3;
                    const input_11 = msg.fields[1].fields[0];
                    command_3 = ((input_11.tag === 1) ? Alert_error(Alert_title("Shipping Order Details", Alert_texts(ofArray(input_11.fields[0].Messages)))) : singleton((dispatch_5) => {
                        dispatch_5(new Msg(8, [msg.fields[0]]));
                    }));
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [(res_1 = msg.fields[1].fields[0], (res_1.tag === 1) ? (new Array(0)) : res_1.fields[0])]), (msg.fields[1].fields[0].tag === 0) ? add(msg.fields[0], new ExpandableItems$1(false, msg.fields[1].fields[0].fields[0]), remove(msg.fields[0], state.Details)) : state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_3];
                }
                default:
                    if (equals(state.DetailsDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (containsKey(msg.fields[0], state.Details)) {
                        return [state, singleton((dispatch_4) => {
                            dispatch_4(new Msg(8, [msg.fields[0]]));
                        })];
                    }
                    else {
                        let shippingOrderOption;
                        const matchValue = state.PagedDataDeferred;
                        shippingOrderOption = ((matchValue.tag === 1) ? void 0 : ((matchValue.tag === 2) ? tryFind_2((x_7) => (x_7.Id === msg.fields[0]), matchValue.fields[0].Data) : void 0));
                        if (shippingOrderOption != null) {
                            const shippingOrder = shippingOrderOption;
                            return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.Details, shippingOrder.Id, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((computation) => {
                                startImmediate(computation);
                            }, () => getDetails_1(shippingOrder.Id, shippingOrder.OwnerType), void 0, (result_1) => (new Msg(1, [shippingOrder.Id, new AsyncMsg$1(2, [result_1])])))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
            }
        case 2:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(2, [void 0]), state.UploadFileDeferred, state.SignalRHub), (input_13 = msg.fields[0].fields[0], (input_13.tag === 1) ? Alert_error(Alert_title("Download Template", Alert_texts(ofArray(input_13.fields[0].Messages)))) : Cmd_none())];
                default:
                    if (equals(state.DownloadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(1, []), state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_10) => {
                            Cmd_OfAsync_start(x_10);
                        }, downloadTemplate, void 0, (arg_3) => (new Msg(2, [new AsyncMsg$1(2, [arg_3])])))];
                    }
            }
        case 3:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    const uploaded = !(msg.fields[0].fields[0].tag === 1);
                    return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, uploaded ? 1 : state.Page, uploaded ? void 0 : state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(2, [void 0]), state.SignalRHub), Cmd_batch(ofArray([(input_17 = msg.fields[0].fields[0], (input_17.tag === 1) ? Alert_error(Alert_title("Upload Shipping Orders", Alert_texts(ofArray(input_17.fields[0].Messages)))) : singleton((dispatch_6) => {
                        dispatch_6(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })), Cmd_resetForm("upload-form")]))];
                }
                default:
                    if (equals(state.UploadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const matchValue_1 = state.UploadFile;
                        if (matchValue_1 != null) {
                            const file_1 = matchValue_1;
                            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(1, []), state.SignalRHub), Cmd_OfAsyncWith_perform((computation_1) => {
                                startImmediate(computation_1);
                            }, () => upload_1(new FileType(3, []), file_1), void 0, (arg_5) => (new Msg(3, [new AsyncMsg$1(2, [arg_5])])))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
            }
        default:
            if (msg.fields[0].OrderType === "ShippingOrder") {
                return [new State(Deferred_map((pagedData) => (new PagedDataResponse$1(pagedData.Page, pagedData.PageSize, pagedData.TotalCount, pagedData.NumberOfPages, map((item) => {
                    if ((int32ToString(item.Id) === msg.fields[0].OrderId) && (item.OwnerType === msg.fields[0].OwnerType)) {
                        return new ShippingOrderResponse(item.Id, item.SourceFileName, item.WarehouseId, item.OrderType, item.SupplierId, item.Priority, item.RequestDeliveryDate, item.ShipToOdmCode, item.PullRequestNumber, item.EmailAddress, item.BuildingNumber, item.SpecialInstruction, item.BillToCompany, item.BillToAddress1, item.BillToAddress2, item.BillToCity, item.BillToState, item.BillToZip, item.BillToCountry, item.ShipToCompany, item.ShipToAddress1, item.ShipToAddress2, item.ShipToCity, item.ShipToState, item.ShipToZip, item.ShipToCountry, item.CreatedBy, item.CreatedOn, item.UploadedOnFormatted, msg.fields[0].OrderStatus, msg.fields[0].OrderStatusDescription, item.OwnerType);
                    }
                    else {
                        return item;
                    }
                }, pagedData.Data))), state.PagedDataDeferred), state.DetailsDeferred, state.Details, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function render(state, dispatch) {
    let elems_40, elems_20, elems_1, elems, matchValue, pagedData, elems_19, elems_4, elems_3, value_24, input_1, elems_2, elems_10, elems_9, elems_6, elems_5, elems_8, elems_7, elems_13, elems_12, elems_11, elems_18, elems_17, elems_16, elems_15, elems_14, input_3, x_1, elems_39;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "is-fluid"])], (elems_40 = [createElement("form", createObj(ofArray([["id", "upload-form"], ["className", "box"], (elems_20 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
        children: "Shipping Orders",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-self-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["disabled", equals(state.PagedDataDeferred, new Deferred$1(1, []))], (value_24 = defaultArg((input_1 = state.SelectedOrderStatus, (input_1 == null) ? void 0 : input_1.Id), ""), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_24)) {
            e.value = value_24;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(6, [ev.target.value]));
    }], (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        children: "-- Order Status --",
        value: "",
        selected: true,
    })), delay(() => map_1((item) => createElement("option", {
        children: item.DisplayValue,
        value: item.Id,
    }), orderStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "is-right"])], (elems_9 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("i", {
        children: ["navigate_before"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Previous",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (_arg) => {
        dispatch(new Msg(5, []));
    }]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_8 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_7 = [createElement("i", {
        children: ["navigate_next"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Next",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]), ["onClick", (_arg_1) => {
        dispatch(new Msg(4, []));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_13 = [createElement("button", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1()))))))))))], ["type", "button"], ["disabled", equals(state.DownloadFileDeferred, new Deferred$1(1, []))], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_11 = [createElement("i", {
        children: ["file_download"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("span", {
        children: [equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? "Downloading..." : "Download Template"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onClick", (_arg_2) => {
        dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["file", "has-name", "is-small", "is-fullwidth"])], (elems_17 = [createElement("label", createObj(ofArray([["className", "file-label"], (elems_16 = [createElement("input", {
        className: join(" ", toList(delay(() => append(singleton_1("file-input"), delay(() => (equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))),
        type: "file",
        name: "OrderFileUpload",
        disabled: equals(state.UploadFileDeferred, new Deferred$1(1, [])),
        onChange: (ev_1) => {
            const files = ev_1.target.files;
            if (!(files == null) && (files.length > 0)) {
                dispatch(new Msg(7, [files.item(0)]));
            }
        },
    }), createElement("span", createObj(ofArray([["className", "file-cta"], (elems_15 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_14 = [createElement("i", {
        children: ["file_upload"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("span", {
        className: "file-label",
        children: equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? "Uploading..." : "Upload",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("span", {
        className: "file-name",
        children: defaultArg((input_3 = state.UploadFile, (input_3 == null) ? void 0 : ((x_1 = input_3, x_1.name))), ""),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_39 = toList(delay(() => {
        let elems_38, elems_37, elems_22, elems_21, elems_36;
        const matchValue_1 = state.PagedDataDeferred;
        switch (matchValue_1.tag) {
            case 1:
                return singleton_1(render_1());
            case 2: {
                const pagedData_1 = matchValue_1.fields[0];
                if (pagedData_1.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Nothing to see here...",
                    }));
                }
                else {
                    const thClasses = ofArray(["px-3", "py-2", "has-text-link"]);
                    const tdClasses = ofArray(["px-3", "py-2"]);
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_38 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_37 = [createElement("thead", createObj(singleton((elems_22 = [createElement("tr", createObj(singleton((elems_21 = [createElement("th", {
                        className: join(" ", thClasses),
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Warehouse ID",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Type",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Supplier ID",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Priority",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Requested Delivery Date",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Ship To ODM",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Pull Request #",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Email Address",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Building #",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Special Instructions",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Bill To",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Ship To",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Uploaded By",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Uploaded On",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Status",
                    }), createElement("th", {
                        className: join(" ", thClasses),
                        children: "Order Status Description",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])))), createElement("tbody", createObj(singleton((elems_36 = toList(delay(() => collect((item_1) => {
                        let elems_31, elems_24, elems_23, elems_26, elems_25, elems_28, elems_27, elems_29, elems_30;
                        return append(singleton_1(createElement("tr", createObj(ofArray([["id", `shipping-order-${item_1.Id}`], (elems_31 = [createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_24 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("material-icons"), delay(() => append(singleton_1("mr-2"), delay(() => append(singleton_1("is-clickable"), delay(() => ((equals(state.DetailsDeferred, new Deferred$1(1, [])) && isCurrentExpandedItem(state, item_1.Id)) ? singleton_1("is-borderless button is-small is-loading") : empty_1()))))))))))], ["onClick", (_arg_3) => {
                            dispatch(new Msg(1, [item_1.Id, new AsyncMsg$1(0, [])]));
                        }], (elems_23 = toList(delay(() => {
                            let matchValue_2;
                            const input_5 = tryFind_1(item_1.Id, state.Details);
                            matchValue_2 = ((input_5 == null) ? void 0 : input_5.IsExpanded);
                            return (matchValue_2 != null) ? (matchValue_2 ? singleton_1(createElement("i", {
                                children: ["expand_less"],
                            })) : singleton_1(createElement("i", {
                                children: ["expand_more"],
                            }))) : singleton_1(createElement("i", {
                                children: ["expand_more"],
                            }));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.WarehouseId,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.OrderType,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SupplierId,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Priority,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: formatDateTime(item_1.RequestDeliveryDate),
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ShipToOdmCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.PullRequestNumber,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.EmailAddress,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.BuildingNumber,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SpecialInstruction,
                        }), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_26 = [createElement("p", createObj(singleton((elems_25 = toList(delay(() => collect((addressInfo) => append(singleton_1(addressInfo), delay(() => singleton_1(createElement("br", {})))), formatBillToAddress(item_1)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_28 = [createElement("p", createObj(singleton((elems_27 = toList(delay(() => collect((addressInfo_1) => append(singleton_1(addressInfo_1), delay(() => singleton_1(createElement("br", {})))), formatShipToAddress(item_1)))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])]))), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.CreatedBy,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.UploadedOnFormatted,
                        }), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_29 = [createElement("span", {
                            className: join(" ", ["tag", "is-flex", "is-rounded", formatOrderStatusAsClass(item_1.OrderStatus)]),
                            children: item_1.OrderStatus,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_30 = [createElement("span", {
                            className: join(" ", [formatStatusDescriptionAsClass(item_1.OrderStatus, item_1.StatusDescription)]),
                            children: item_1.StatusDescription,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))), delay(() => {
                            let elems_35, elems_34, elems_33, elems_32, children_2, children, children_6;
                            const matchValue_3 = tryFind_1(item_1.Id, state.Details);
                            if (matchValue_3 != null) {
                                const details = matchValue_3;
                                return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                    let input_7;
                                    return !defaultArg((input_7 = tryFind_1(item_1.Id, state.Details), (input_7 == null) ? void 0 : input_7.IsExpanded), false) ? singleton_1("is-hidden") : empty_1();
                                })))], (elems_35 = [createElement("td", createObj(ofArray([["className", join(" ", ["px-6", "py-4"])], ["colSpan", 100], (elems_34 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "px-6"])], (elems_33 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_32 = [(children_2 = singleton((children = ofArray([createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Pull Request #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Pull Request Line #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "AWS Part #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Supplier Part #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Order Qty",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "AWS Reference #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "ODM PO #",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Additional Reference 2",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Special Instructions",
                                }), createElement("th", {
                                    className: join(" ", thClasses),
                                    children: "Shipment Value",
                                })]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                                }))), createElement("thead", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                })), (children_6 = toList(delay(() => map_1((detail) => {
                                    const children_4 = ofArray([createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.PullRequestNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.PullRequestLineNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AwsPartNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.SupplierPartNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.OrderQty,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AwsReferenceNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.OdmPoNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AdditionalReference2,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.SpecialInstruction,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: `${detail.ShipmentValue} ${detail.ShipmentValueCurrencyCode}`,
                                    })]);
                                    return createElement("tr", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                    });
                                }, details.Items))), createElement("tbody", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])]))));
                            }
                            else {
                                return singleton_1(defaultOf());
                            }
                        }));
                    }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))));
                }
            }
            default:
                return singleton_1(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])));
}

