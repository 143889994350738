import { ApiMessageResponse_$reflection, ApiMessageResponseModule_mapMessages } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";

export function tryParseAsApiMessage(response) {
    switch (response.statusCode) {
        case 401:
            return new FSharpResult$2(1, [ApiMessageResponseModule_mapMessages(["Failed authentication"])]);
        case 403:
            return new FSharpResult$2(1, [ApiMessageResponseModule_mapMessages(["Request not allowed"])]);
        default: {
            let input_6;
            const response_2 = response;
            const toGeneralError = (error) => {
                console.error(some("API error"), error);
                return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
            };
            if (isNullOrWhiteSpace(response_2.responseText)) {
                input_6 = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
            }
            else {
                let input_4;
                const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                try {
                    input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(ApiMessageResponse_$reflection()))]));
                }
                catch (ex) {
                    input_4 = (new FSharpResult$2(1, [ex.message]));
                }
                input_6 = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
            }
            if (input_6.tag === 1) {
                return new FSharpResult$2(1, [input_6.fields[0]]);
            }
            else {
                return new FSharpResult$2(1, [input_6.fields[0]]);
            }
        }
    }
}

