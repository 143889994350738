import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { LookupResponseModule_ToSelectItems, ReportRequest, ApiMessageResponse_$reflection, PagedDataResponse$1_$reflection, MaterialReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, int32_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractDropDownFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addMultiSearchFilter, StateSelectFilterModule_addDropDownFilter, TypeStateHeldModule_toDropDownSearch, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsIds, StateSelectFilterHelper_dropDownValue, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { render as render_1, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_2, State_$reflection as State_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { Default_pagedData, SearchFilterKey, DataSourceItem_$reflection } from "../Models.fs.js";
import { render as render_2, update as update_2, init as init_3, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_2 } from "../Components/ExportReportDialog.fs.js";
import { render as render_3, update as update_3, extractName as extractName_1, init as init_2, Msg_$reflection as Msg_$reflection_3, State_$reflection as State_$reflection_3 } from "../Components/SelectComponent.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { createObj, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getYesNoTBD, getMaterials, getWarehouses, getAccounts, getProjects } from "../API/DataSourceAPI.fs.js";
import { isEmpty, map, singleton, empty, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { getMaterials as getMaterials_1 } from "../API/MaterialAPI.fs.js";
import { exportMaterials } from "../API/ReportAPI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map as map_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_4 } from "../Components/Loader.fs.js";
import { formatInt } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, ExportDeferred, SelectFilters, ProjectSearch, SelectedProjects, AccountSearch, SelectedAccounts, WarehouseSearch, SelectedWarehouses, MaterialSearch, SelectedMaterials, ExportReportState, ShowProjectSearch, Name, ReservationFlag, SerialFlag, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ProjectSearch = ProjectSearch;
        this.SelectedProjects = SelectedProjects;
        this.AccountSearch = AccountSearch;
        this.SelectedAccounts = SelectedAccounts;
        this.WarehouseSearch = WarehouseSearch;
        this.SelectedWarehouses = SelectedWarehouses;
        this.MaterialSearch = MaterialSearch;
        this.SelectedMaterials = SelectedMaterials;
        this.ExportReportState = ExportReportState;
        this.ShowProjectSearch = ShowProjectSearch;
        this.Name = Name;
        this.ReservationFlag = ReservationFlag;
        this.SerialFlag = SerialFlag;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.MaterialsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(MaterialReportResponse_$reflection()))], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ProjectSearch", State_$reflection_1()], ["SelectedProjects", list_type(DataSourceItem_$reflection())], ["AccountSearch", State_$reflection_1()], ["SelectedAccounts", list_type(DataSourceItem_$reflection())], ["WarehouseSearch", State_$reflection_1()], ["SelectedWarehouses", list_type(DataSourceItem_$reflection())], ["MaterialSearch", State_$reflection_1()], ["SelectedMaterials", list_type(DataSourceItem_$reflection())], ["ExportReportState", State_$reflection_2()], ["ShowProjectSearch", bool_type], ["Name", State_$reflection_3()], ["ReservationFlag", State_$reflection_3()], ["SerialFlag", State_$reflection_3()], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "ExportReportMsg", "MultiSelectSearchMsg", "SetSearchItems", "DropDownSearchMsg", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.MaterialsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(MaterialReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(MaterialReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", Msg_$reflection_3()]], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    const reservationFlagOption = StateSelectFilterHelper_dropDownValue(state.SelectFilters, toString(new SearchFilterKey(18, [])));
    const serialFlagOption = StateSelectFilterHelper_dropDownValue(state.SelectFilters, toString(new SearchFilterKey(19, [])));
    const materialLookupCodeOption = StateSelectFilterHelper_dropDownValue(state.SelectFilters, toString(new SearchFilterKey(27, [])));
    const materialNameOption = StateSelectFilterHelper_dropDownValue(state.SelectFilters, toString(new SearchFilterKey(28, [])));
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    const ProjectIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, [])));
    const AccountIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(0, [])));
    const WarehouseIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(3, [])));
    const MaterialIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    const ReservationFlag = (reservationFlagOption != null) ? reservationFlagOption.SelectValue : "None";
    const SerialFlag = (serialFlagOption != null) ? serialFlagOption.SelectValue : "None";
    const DateType = defaultOf();
    const StartDate = defaultOf();
    const EndDate = defaultOf();
    return new ReportRequest("Materials.xlsx", Description, ProjectIds, AccountIds, WarehouseIds, MaterialIds, new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], [], [], [], [], [], [], new Int32Array([]), [], [], "", ReservationFlag, SerialFlag, DateType, (materialLookupCodeOption != null) ? materialLookupCodeOption.SelectValue : "", (materialNameOption != null) ? materialNameOption.SelectValue : "", StartDate, EndDate, state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if (((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.AccountIds.length === 0)) ? true : !(request.WarehouseIds.length === 0)) ? true : !(request.MaterialIds.length === 0)) ? true : ((request.ReservationFlag === "Yes") ? true : (request.ReservationFlag === "No"))) ? true : ((request.SerialFlag === "Yes") ? true : (request.SerialFlag === "No"))) ? true : !(request.MaterialLookupCode === "")) {
        return true;
    }
    else {
        return !(request.MaterialName === "");
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToDropDown(typeHeldState) {
    return TypeStateHeldModule_toDropDownSearch(typeHeldState);
}

export function init(user) {
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const projectSearchState = patternInput[0];
    const patternInput_1 = init_1(new SearchFilterKey(0, []), (searchCriteria_1) => getAccounts(user, searchCriteria_1));
    const accountSearchState = patternInput_1[0];
    const patternInput_2 = init_1(new SearchFilterKey(3, []), (searchCriteria_2) => getWarehouses(user, searchCriteria_2));
    const warehouseSearchState = patternInput_2[0];
    const patternInput_3 = init_1(new SearchFilterKey(2, []), (searchCriteria_3) => getMaterials(user, searchCriteria_3));
    const materialSearchState = patternInput_3[0];
    const patternInput_4 = init_2(toString(new SearchFilterKey(27, [])), LookupResponseModule_ToSelectItems(user.MaterialLookupCodes), "Choose Supplier Part #");
    const names = user.MaterialNames.filter((item) => !(item.DisplayValue === ""));
    const nameSearchState = init_2(toString(new SearchFilterKey(28, [])), LookupResponseModule_ToSelectItems(names), "Choose AWS Part #")[0];
    const reservationFlagSearchState = init_2(toString(new SearchFilterKey(18, [])), getYesNoTBD, "Choose Reservation Flag ..")[0];
    const serialFlagSearchState = init_2(toString(new SearchFilterKey(19, [])), getYesNoTBD, "Choose Serial Flag ..")[0];
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addDropDownFilter(new SearchFilterKey(27, []), patternInput_4[0])));
    void (selectFilters.push(StateSelectFilterModule_addDropDownFilter(new SearchFilterKey(28, []), nameSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addDropDownFilter(new SearchFilterKey(18, []), reservationFlagSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addDropDownFilter(new SearchFilterKey(19, []), serialFlagSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), projectSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(0, []), accountSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(3, []), warehouseSearchState)));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), materialSearchState)));
    const patternInput_8 = init_3("Export");
    return [new State(new Deferred$1(0, []), new Deferred$1(0, []), ofArray(selectFilters.slice()), projectSearchState, empty(), accountSearchState, empty(), warehouseSearchState, empty(), materialSearchState, empty(), patternInput_8[0], !(user.Role === 3), nameSearchState, reservationFlagSearchState, serialFlagSearchState, 1), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(3, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(3, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(3, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(3, [Item_3])), patternInput_3[1]), patternInput_8[1]]))];
}

export function update(msg, state) {
    let input_3;
    switch (msg.tag) {
        case 7:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Materials Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(new Deferred$1(0, []), state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getMaterials_1, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [void 0]), state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportMaterials, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 3: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                const command_4 = (componentIntent.tag === 1) ? Cmd_map((Item_5) => (new Msg(3, [Item_5])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(3, [Item_4])), componentCmd), singleton((dispatch_2) => {
                    dispatch_2(new Msg(4, [componentIntent.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_3) => {
                    if (toString(x_3.Id) === filterId) {
                        return new StateSelectFilter(x_3.Id, new TypeStateHeld(0, [patternInput[0]]), x_3.SelectedItems);
                    }
                    else {
                        return x_3;
                    }
                }, state.SelectFilters), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_5) => {
                if (toString(x_5.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_5.Id, x_5.State, msg.fields[0].Items);
                }
                else {
                    return x_5;
                }
            }, state.SelectFilters), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, isEmpty(msg.fields[0].Items) ? state.Page : 1), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 2:
            if (!hasFilters(state)) {
                return [state, Toast_info(Toast_title("Invalid Option", Toast_text("Cannot export a report without filters")))];
            }
            else {
                const patternInput_1 = update_2(msg.fields[0], state.ExportReportState);
                const componentState_1 = patternInput_1[0];
                const componentIntent_1 = patternInput_1[2];
                switch (componentIntent_1.tag) {
                    case 1:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                    case 2:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), Cmd_none()];
                    default:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, componentState_1, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), singleton((dispatch_4) => {
                            dispatch_4(new Msg(1, [new AsyncMsg$1(0, [])]));
                        })];
                }
            }
        case 5: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const dropDownFilter = StateSelectFilterHelper_extractDropDownFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (dropDownFilter != null) {
                const patternInput_2 = update_3(msg.fields[0], dropDownFilter);
                const componentIntent_2 = patternInput_2[2];
                const componentCmd_2 = patternInput_2[1];
                const command_7 = (componentIntent_2.tag === 1) ? Cmd_map((Item_7) => (new Msg(5, [Item_7])), componentCmd_2) : ((componentIntent_2.tag === 2) ? Cmd_map((Item_8) => (new Msg(5, [Item_8])), componentCmd_2) : Cmd_batch(ofArray([Cmd_map((Item_6) => (new Msg(5, [Item_6])), componentCmd_2), singleton((dispatch_5) => {
                    dispatch_5(new Msg(0, [new AsyncMsg$1(0, [])]));
                })])));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_6) => {
                    if (toString(x_6.Id) === filterId_1) {
                        return new StateSelectFilter(x_6.Id, new TypeStateHeld(2, [patternInput_2[0]]), x_6.SelectedItems);
                    }
                    else {
                        return x_6;
                    }
                }, state.SelectFilters), state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page), command_7];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ProjectSearch, state.SelectedProjects, state.AccountSearch, state.SelectedAccounts, state.WarehouseSearch, state.SelectedWarehouses, state.MaterialSearch, state.SelectedMaterials, state.ExportReportState, state.ShowProjectSearch, state.Name, state.ReservationFlag, state.SerialFlag, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function renderRow1(state, dispatch) {
    let elems_11, elems_10, elems_9;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = toList(delay(() => {
        let elems;
        return append(state.ShowProjectSearch ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(3, [arg]));
        }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_1(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                dispatch(new Msg(3, [arg_1]));
            }), new SearchFilterKey(3, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_8, elems_7, elems_3, elems_2, elems_5, elems_4, elems_6;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_7 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                    dispatch(new Msg(7, []));
                }], (elems_3 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_2 = [createElement("span", {
                    children: ["navigate_before"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Previous",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                    dispatch(new Msg(6, []));
                }], (elems_5 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_4 = [createElement("span", {
                    children: ["navigate_next"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Next",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(singleton((elems_6 = [render_2(state.ExportReportState, (arg_2) => {
                    dispatch(new Msg(2, [arg_2]));
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
}

export function renderRow2(state, dispatch) {
    let elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_3(convertStateToDropDown(filter.State), (arg) => {
        dispatch(new Msg(5, [arg]));
    }), new SearchFilterKey(27, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_3(convertStateToDropDown(filter_1.State), (arg_1) => {
        dispatch(new Msg(5, [arg_1]));
    }), new SearchFilterKey(28, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_3(convertStateToDropDown(filter_2.State), (arg_2) => {
        dispatch(new Msg(5, [arg_2]));
    }), new SearchFilterKey(18, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderRow3(state, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_3(convertStateToDropDown(filter.State), (arg) => {
        dispatch(new Msg(5, [arg]));
    }), new SearchFilterKey(19, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function renderReport(state, dispatch) {
    let elems_5;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_5 = toList(delay(() => {
        let elems_4, elems_3, elems, children, elems_2;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_4());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No items were found that meet the search criteria",
                    }));
                }
                else {
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", ["px-3", "py-2"]),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_4 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_3 = [createElement("thead", createObj(singleton((elems = [(children = ofArray([tableHeader("Id"), tableHeader("Supplier Part #"), tableHeader("AWS Part #"), tableHeader("Description"), tableHeader("Group Name"), tableHeader("Project Name"), tableHeader("Project Lookup Code"), tableHeader("Use Reservation"), tableHeader("Requires Child Serials")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton((elems_2 = toList(delay(() => map_1((item) => {
                        let elems_1;
                        return createElement("tr", createObj(singleton((elems_1 = toList(delay(() => append(singleton_1(tableData(formatInt(item.Id))), delay(() => append(singleton_1(tableData(item.MaterialLookupCode)), delay(() => append(singleton_1(tableData(item.Name)), delay(() => append(singleton_1(tableData(item.MaterialDescription)), delay(() => append(singleton_1(tableData(item.MaterialGroupName)), delay(() => append(singleton_1(tableData(item.ProjectName)), delay(() => append(singleton_1(tableData(item.ProjectLookupCode)), delay(() => append(singleton_1(tableData(item.UseReservation)), delay(() => singleton_1(tableData(item.RequiresChildSerials)))))))))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Select filters to run the query",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function render(state, dispatch) {
    let elems_5, elems_1, elems, elems_4, elems_3, elems_2, matchValue, pagedData;
    return createElement("div", createObj(singleton((elems_5 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
        className: join(" ", ["has-link"]),
        href: "#/scheduled-reports",
        children: "Scheduled Reports",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_4 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "Materials Query",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems_2 = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), renderReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))));
}

