import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, option_type, class_type, enum_type, int32_type, string_type, record_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { toString, compareTo } from "../fable_modules/fable-library.4.10.0/Date.js";
import { value as value_129 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { formatDateTimeAsCanonical } from "../Domain/Formatter.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { DateTime_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";

export class IsModalActiveMsgData extends Record {
    constructor(Show, ResetCancelData) {
        super();
        this.Show = Show;
        this.ResetCancelData = ResetCancelData;
    }
}

export function IsModalActiveMsgData_$reflection() {
    return record_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.IsModalActiveMsgData", [], IsModalActiveMsgData, () => [["Show", bool_type], ["ResetCancelData", bool_type]]);
}

export function MultiDateRangeTypeModule_value(dateRangeType) {
    switch (dateRangeType) {
        case 1:
            return "Created";
        case 2:
            return "Processed";
        case 3:
            return "Completed";
        case 4:
            return "Cancelled";
        default:
            return "Choose";
    }
}

export function MultiDateRangeTypeModule_toString(value) {
    switch (value) {
        case "Created":
            return 1;
        case "Processed":
            return 2;
        case "Completed":
            return 3;
        case "Cancelled":
            return 4;
        default:
            return 0;
    }
}

export function MultiDateRangeTypeModule_toInt(dateRangeType) {
    return dateRangeType;
}

export class State extends Record {
    constructor(Name, IsModalActive, OkButtonActive, DateRangeTypeSelection, DateRangeTypeSelectionVisible, DateStart, DateEnd, DateRangeTypeCacheForCancel, DateStartCacheForCancel, DateEndCacheForCancel) {
        super();
        this.Name = Name;
        this.IsModalActive = IsModalActive;
        this.OkButtonActive = OkButtonActive;
        this.DateRangeTypeSelection = (DateRangeTypeSelection | 0);
        this.DateRangeTypeSelectionVisible = DateRangeTypeSelectionVisible;
        this.DateStart = DateStart;
        this.DateEnd = DateEnd;
        this.DateRangeTypeCacheForCancel = (DateRangeTypeCacheForCancel | 0);
        this.DateStartCacheForCancel = DateStartCacheForCancel;
        this.DateEndCacheForCancel = DateEndCacheForCancel;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.State", [], State, () => [["Name", string_type], ["IsModalActive", IsModalActiveMsgData_$reflection()], ["OkButtonActive", bool_type], ["DateRangeTypeSelection", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateRangeTypeSelectionVisible", bool_type], ["DateStart", option_type(class_type("System.DateTime"))], ["DateEnd", option_type(class_type("System.DateTime"))], ["DateRangeTypeCacheForCancel", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateStartCacheForCancel", option_type(class_type("System.DateTime"))], ["DateEndCacheForCancel", option_type(class_type("System.DateTime"))]]);
}

export class MultiDateFilterNewSettings extends Record {
    constructor(DateRangeType, DateStart, DateEnd) {
        super();
        this.DateRangeType = (DateRangeType | 0);
        this.DateStart = DateStart;
        this.DateEnd = DateEnd;
    }
}

export function MultiDateFilterNewSettings_$reflection() {
    return record_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateFilterNewSettings", [], MultiDateFilterNewSettings, () => [["DateRangeType", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateStart", option_type(class_type("System.DateTime"))], ["DateEnd", option_type(class_type("System.DateTime"))]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MultiDateRangeChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.Intent", [], Intent, () => [[["Item", MultiDateFilterNewSettings_$reflection()]], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsModalActive", "SetOkButtonActive", "OkButtonClicked", "MultiDateRangeTypeSelection", "MultiDateStart", "MultiDateEnd", "MultiDatesClear", "MultiDatesCancel"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.Msg", [], Msg, () => [[["isModalActive", IsModalActiveMsgData_$reflection()]], [["isOkButtonActive", bool_type]], [], [["Item", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [], []]);
}

export function init(name, dateRangeTypeSelectionVisible) {
    return [new State(name, new IsModalActiveMsgData(false, true), false, 0, dateRangeTypeSelectionVisible, void 0, void 0, 0, void 0, void 0), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 4: {
            const startDateOption = msg.fields[0];
            const matchValue = state.DateEnd;
            if (matchValue != null) {
                const dtEnd = matchValue;
                if (compareTo(value_129(startDateOption), dtEnd) === -1) {
                    return [new State(state.Name, state.IsModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, startDateOption, state.DateEnd, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
                }
                else {
                    return [state, Cmd_none(), new Intent(1, [])];
                }
            }
            else {
                return [new State(state.Name, state.IsModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, startDateOption, state.DateEnd, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
            }
        }
        case 5: {
            const endDateOption = msg.fields[0];
            const matchValue_1 = state.DateStart;
            if (matchValue_1 != null) {
                const dtStart_1 = matchValue_1;
                if (!(compareTo(value_129(endDateOption), dtStart_1) === -1)) {
                    return [new State(state.Name, state.IsModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, state.DateStart, endDateOption, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
                }
                else {
                    return [state, Cmd_none(), new Intent(1, [])];
                }
            }
            else {
                return [new State(state.Name, state.IsModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, state.DateStart, endDateOption, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
            }
        }
        case 6:
            return [new State(state.Name, state.IsModalActive, state.OkButtonActive, 0, state.DateRangeTypeSelectionVisible, void 0, void 0, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
        case 7:
            return [new State(state.Name, new IsModalActiveMsgData(false, false), state.OkButtonActive, state.DateRangeTypeCacheForCancel, state.DateRangeTypeSelectionVisible, state.DateStartCacheForCancel, state.DateEndCacheForCancel, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
        case 1:
            return [new State(state.Name, state.IsModalActive, msg.fields[0], state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, state.DateStart, state.DateEnd, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
        case 2:
            return [state, Cmd_none(), new Intent(0, [new MultiDateFilterNewSettings(state.DateRangeTypeSelection, state.DateStart, state.DateEnd)])];
        case 0: {
            const isModalActive = msg.fields[0];
            return [isModalActive.ResetCancelData ? (new State(state.Name, isModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, state.DateStart, state.DateEnd, state.DateRangeTypeSelection, state.DateStart, state.DateEnd)) : (new State(state.Name, isModalActive, state.OkButtonActive, state.DateRangeTypeSelection, state.DateRangeTypeSelectionVisible, state.DateStart, state.DateEnd, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel)), Cmd_none(), new Intent(1, [])];
        }
        default:
            return [new State(state.Name, state.IsModalActive, state.OkButtonActive, msg.fields[0], state.DateRangeTypeSelectionVisible, state.DateStart, state.DateEnd, state.DateRangeTypeCacheForCancel, state.DateStartCacheForCancel, state.DateEndCacheForCancel), Cmd_none(), new Intent(1, [])];
    }
}

function dateRangeToString(state) {
    let matchValue_2;
    let formattedDateStart;
    const matchValue = state.DateStart;
    formattedDateStart = ((matchValue != null) ? formatDateTimeAsCanonical(matchValue) : "Not set");
    let formattedDateEnd;
    const matchValue_1 = state.DateEnd;
    formattedDateEnd = ((matchValue_1 != null) ? formatDateTimeAsCanonical(matchValue_1) : "Not set");
    if (state.DateRangeTypeSelection === 0) {
        return `Select ${state.Name}`;
    }
    else {
        return `${(matchValue_2 = (state.DateRangeTypeSelection | 0), (matchValue_2 === 0) ? "Choose" : ((matchValue_2 === 1) ? "Created" : ((matchValue_2 === 2) ? "Processed" : ((matchValue_2 === 3) ? "Completed" : ((matchValue_2 === 4) ? "Cancelled" : "Choose")))))}: ${formattedDateStart} - ${formattedDateEnd}`;
    }
}

export function render(state, dispatch) {
    let elems_15, elems_14, elems_1, elems, elems_12, children, elems_5, elems_4, elems_3, elems_2, elems_8, elems_6, elems_7, matchValue_1, elems_11, elems_9, elems_10, matchValue_3, elems_13, elems_20, elems_16, elems_19, elems_18, elems_17;
    const modal = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("modal"), delay(() => append(singleton("px-4"), delay(() => (state.IsModalActive.Show ? singleton("is-active") : empty()))))))))], (elems_15 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", "modal-card"], (elems_14 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems_1 = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: `Select ${state.Name}`,
    }), createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems = [createElement("i", {
        children: ["check"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("section", createObj(ofArray([["className", join(" ", ["modal-card-body"])], (elems_12 = [(children = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_5 = [createElement("div", {
        className: join(" ", toList(delay(() => (!state.DateRangeTypeSelectionVisible ? append(singleton("column"), delay(() => append(singleton("is-one-third"), delay(() => append(singleton("label-is-small"), delay(() => singleton("is-hidden"))))))) : append(singleton("column"), delay(() => singleton("is-one-third"))))))),
        children: "Date Type",
    }), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (!state.DateRangeTypeSelectionVisible ? append(singleton("column"), delay(() => append(singleton("is-two-thirds"), delay(() => singleton("is-hidden"))))) : singleton("column")))))], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-small", "is-fullwidth"])], (elems_3 = [createElement("select", createObj(ofArray([["value", MultiDateRangeTypeModule_value(state.DateRangeTypeSelection)], ["onChange", (ev) => {
        dispatch(new Msg(3, [MultiDateRangeTypeModule_toString(ev.target.value)]));
    }], (elems_2 = [createElement("option", {
        value: "Choose",
        children: "Choose",
    }), createElement("option", {
        value: "Created",
        children: "Created",
    }), createElement("option", {
        value: "Processed",
        children: "Processed",
    }), createElement("option", {
        value: "Completed",
        children: "Completed",
    }), createElement("option", {
        value: "Cancelled",
        children: "Cancelled",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), createElement("div", {
        className: "block",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_6 = [createElement("div", {
        className: join(" ", ["label-is-small"]),
        children: "Start date",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-two-thirds"])], (elems_7 = [createElement("input", {
        className: join(" ", ["input", "is-small"]),
        onChange: (e) => {
            const element = e.target;
            if (isNullOrWhiteSpace(element.value)) {
                dispatch(new Msg(4, [void 0]));
            }
            else {
                dispatch(new Msg(4, [DateTime_fromString(element.value)]));
            }
        },
        type: "date",
        value: (matchValue_1 = state.DateStart, (matchValue_1 == null) ? state.Name : toString(matchValue_1, "yyyy-MM-dd")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", {
        className: "block",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_9 = [createElement("div", {
        className: join(" ", ["label-is-small"]),
        children: "End date",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-two-thirds"])], (elems_10 = [createElement("input", {
        className: join(" ", ["input", "is-small"]),
        onChange: (e_1) => {
            const element_1 = e_1.target;
            if (isNullOrWhiteSpace(element_1.value)) {
                dispatch(new Msg(5, [void 0]));
            }
            else {
                dispatch(new Msg(5, [DateTime_fromString(element_1.value)]));
            }
        },
        type: "date",
        value: (matchValue_3 = state.DateEnd, (matchValue_3 == null) ? state.Name : toString(matchValue_3, "yyyy-MM-dd")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_13 = [createElement("button", {
        className: join(" ", ["button", "is-primary", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Ok",
        onClick: (_arg) => {
            dispatch(new Msg(0, [new IsModalActiveMsgData(false, false)]));
            dispatch(new Msg(2, []));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Clear",
        onClick: (_arg_1) => {
            dispatch(new Msg(6, []));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_2) => {
            dispatch(new Msg(7, []));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])));
    return createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_20 = [modal, createElement("p", createObj(ofArray([["className", join(" ", ["control", "is-expanded"])], (elems_16 = [createElement("input", {
        className: join(" ", ["input", "is-small", "is-clickable"]),
        placeholder: dateRangeToString(state),
        readOnly: true,
        onClick: (_arg_3) => {
            dispatch(new Msg(0, [new IsModalActiveMsgData(true, false)]));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_19 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["onClick", (_arg_4) => {
        dispatch(new Msg(0, [new IsModalActiveMsgData(true, false)]));
    }], (elems_18 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems_17 = [createElement("i", {
        children: ["list"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("span", {
        className: "is-sr-only",
        children: `Select ${state.Name}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])));
}

