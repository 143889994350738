import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { render as render_1, State as State_1, update as update_1, init as init_1, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/SelectComponent.fs.js";
import { union_type, record_type, list_type, int32_type, option_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { UserRoleSettings, ClaimQueryRequest, ApiMessageResponse_$reflection, RoleIdResponse_$reflection, FootPrintClaimResponse_$reflection, SelectItem_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { createObj, int32ToString, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Default_footPrintClaimResponse, Default_roleIdResponse, SearchFilterKey } from "../Models.fs.js";
import { append, filter, exists as exists_1, map, find, ofArray, singleton, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { getRoles } from "../API/DataSourceAPI.fs.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_success, Alert_text, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { saveClaims, getClaims, getUserRole, getUsers } from "../API/ClaimAPI.fs.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Int32.js";
import { value as value_65 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, map as map_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { FootPrintClaimModule_toString, FootPrintClaimModule_toInt } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";

export class State extends Record {
    constructor(UserSearch, RoleSearch, SaveClaimsDeferred, SelectedUser, SelectedRole, CheckedClaims, GetClaimsDeferred, GetUsersDeferred, GetUserRoleDeferred) {
        super();
        this.UserSearch = UserSearch;
        this.RoleSearch = RoleSearch;
        this.SaveClaimsDeferred = SaveClaimsDeferred;
        this.SelectedUser = SelectedUser;
        this.SelectedRole = SelectedRole;
        this.CheckedClaims = CheckedClaims;
        this.GetClaimsDeferred = GetClaimsDeferred;
        this.GetUsersDeferred = GetUsersDeferred;
        this.GetUserRoleDeferred = GetUserRoleDeferred;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.AssignUserRole.State", [], State, () => [["UserSearch", State_$reflection_1()], ["RoleSearch", State_$reflection_1()], ["SaveClaimsDeferred", Deferred$1_$reflection(bool_type)], ["SelectedUser", option_type(SelectItem_$reflection())], ["SelectedRole", option_type(SelectItem_$reflection())], ["CheckedClaims", list_type(int32_type)], ["GetClaimsDeferred", Deferred$1_$reflection(FootPrintClaimResponse_$reflection())], ["GetUsersDeferred", Deferred$1_$reflection(list_type(SelectItem_$reflection()))], ["GetUserRoleDeferred", Deferred$1_$reflection(RoleIdResponse_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetClaimsAsync", "SetSelectedUser", "SetSelectedRole", "SaveClaimsAsync", "UserSearchMsg", "RoleSearchMsg", "ToggleCheckedClaim", "GetUsersAsync", "GetUserRoleAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.AssignUserRole.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [FootPrintClaimResponse_$reflection(), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", FootPrintClaimResponse_$reflection()]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", option_type(SelectItem_$reflection())]], [["Item", option_type(SelectItem_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [bool_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", bool_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_1()]], [["Item", int32_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SelectItem_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SelectItem_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [RoleIdResponse_$reflection(), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", RoleIdResponse_$reflection()]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]]]);
}

function hasFilters(state) {
    return !equals(state.SelectedUser, void 0);
}

export function init() {
    return [new State(init_1(toString(new SearchFilterKey(25, [])), empty(), "Choose User ..")[0], init_1(toString(new SearchFilterKey(26, [])), getRoles, "Choose Role ..")[0], new Deferred$1(0, []), void 0, void 0, empty(), new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, [])), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch(new Msg(7, [new AsyncMsg$1(0, [])]));
    })))];
}

export function update(msg, state) {
    let bind$0040, matchValue_1, matchValue_2, matchValue_3, matchValue_4;
    switch (msg.tag) {
        case 1:
            return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, msg.fields[0], state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), singleton((dispatch_2) => {
                dispatch_2(new Msg(8, [new AsyncMsg$1(0, [])]));
            })];
        case 5: {
            const patternInput_1 = update_1(msg.fields[0], state.RoleSearch);
            const componentIntent_1 = patternInput_1[2];
            const componentCmd_1 = patternInput_1[1];
            return [new State(state.UserSearch, patternInput_1[0], state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), (componentIntent_1.tag === 1) ? Cmd_batch(ofArray([Cmd_map((Item_3) => (new Msg(5, [Item_3])), componentCmd_1), singleton((dispatch_4) => {
                dispatch_4(new Msg(2, [void 0]));
            })])) : ((componentIntent_1.tag === 2) ? Cmd_none() : Cmd_batch(ofArray([Cmd_map((Item_2) => (new Msg(5, [Item_2])), componentCmd_1), singleton((dispatch_3) => {
                dispatch_3(new Msg(2, [componentIntent_1.fields[0]]));
            })])))];
        }
        case 2:
            return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, msg.fields[0], state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), singleton((dispatch_5) => {
                dispatch_5(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 7:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_5;
                    const input_1 = msg.fields[0].fields[0];
                    command_5 = ((input_1.tag === 1) ? Alert_error(Alert_title("Fetch Users", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    let users;
                    const input_3 = msg.fields[0].fields[0];
                    users = ((input_3.tag === 1) ? empty() : input_3.fields[0]);
                    return [new State(init_1(toString(new SearchFilterKey(25, [])), users, "Choose User ..")[0], state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, new Deferred$1(2, [users]), state.GetUserRoleDeferred), command_5];
                }
                default:
                    if (equals(state.GetUsersDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, new Deferred$1(1, []), state.GetUserRoleDeferred), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getUsers, void 0, (arg) => (new Msg(7, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 8:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_6;
                    const input_5 = msg.fields[0].fields[0];
                    command_6 = ((input_5.tag === 1) ? Alert_error(Alert_title("Fetch User Role", Alert_texts(ofArray(input_5.fields[0].Messages)))) : Cmd_none());
                    let role;
                    const input_7 = msg.fields[0].fields[0];
                    role = ((input_7.tag === 1) ? Default_roleIdResponse : input_7.fields[0]);
                    const newRoleItem = find((x_7) => (x_7.Id === int32ToString(role.RoleId)), state.RoleSearch.Items);
                    return [new State(state.UserSearch, (bind$0040 = state.RoleSearch, new State_1(bind$0040.Name, bind$0040.Disabled, bind$0040.Items, newRoleItem, bind$0040.PlaceholderText)), state.SaveClaimsDeferred, state.SelectedUser, newRoleItem, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, new Deferred$1(2, [role])), Cmd_batch(ofArray([command_6, singleton((dispatch_6) => {
                        dispatch_6(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })]))];
                }
                default:
                    if (equals(state.GetUserRoleDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const matchValue = state.SelectedUser;
                        if (matchValue == null) {
                            return [state, Cmd_none()];
                        }
                        else {
                            return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation_1) => {
                                startImmediate(computation_1);
                            }, getUserRole, matchValue.Id, (arg_2) => (new Msg(8, [new AsyncMsg$1(2, [arg_2])])))];
                        }
                    }
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_7;
                    const input_9 = msg.fields[0].fields[0];
                    command_7 = ((input_9.tag === 1) ? Alert_error(Alert_title("Fetch Permissions", Alert_texts(ofArray(input_9.fields[0].Messages)))) : Cmd_none());
                    let claims;
                    const input_11 = msg.fields[0].fields[0];
                    claims = ((input_11.tag === 1) ? Default_footPrintClaimResponse : input_11.fields[0]);
                    return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, map((x_12) => x_12.ClaimId, claims.UserClaims), new Deferred$1(2, [claims]), state.GetUsersDeferred, state.GetUserRoleDeferred), command_7];
                }
                default:
                    if (equals(state.GetClaimsDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, new Deferred$1(0, []), state.GetUsersDeferred, state.GetUserRoleDeferred), Cmd_none()];
                    }
                    else {
                        return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, new Deferred$1(1, []), state.GetUsersDeferred, state.GetUserRoleDeferred), Cmd_OfAsyncWith_perform((computation_2) => {
                            startImmediate(computation_2);
                        }, getClaims, new ClaimQueryRequest((matchValue_1 = state.SelectedUser, (matchValue_1 == null) ? "" : matchValue_1.Id), (matchValue_2 = state.SelectedRole, (matchValue_2 == null) ? -1 : parse(matchValue_2.Id, 511, false, 32))), (arg_4) => (new Msg(0, [new AsyncMsg$1(2, [arg_4])])))];
                    }
            }
        case 3:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.UserSearch, state.RoleSearch, new Deferred$1(2, [true]), state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Roles and Permissions", Alert_text("The User or Role is invalid. Please try again."))) : Toast_success(Toast_title("Roles and Permissions", Toast_text(`Role [${value_65(state.SelectedRole).SelectValue}] for user [${value_65(state.SelectedUser).SelectValue} has been updated]`)))];
                default:
                    if (equals(state.SaveClaimsDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(state.UserSearch, state.RoleSearch, new Deferred$1(0, []), state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), Cmd_none()];
                    }
                    else {
                        return [new State(state.UserSearch, state.RoleSearch, new Deferred$1(1, []), state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), Cmd_OfAsyncWith_perform((computation_3) => {
                            startImmediate(computation_3);
                        }, saveClaims, new UserRoleSettings(state.CheckedClaims, (matchValue_3 = state.SelectedUser, (matchValue_3 == null) ? "-1" : matchValue_3.Id), (matchValue_4 = state.SelectedRole, (matchValue_4 == null) ? "-1" : matchValue_4.Id)), (arg_6) => (new Msg(3, [new AsyncMsg$1(2, [arg_6])])))];
                    }
            }
        case 6:
            return [new State(state.UserSearch, state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, exists_1((x_16) => (x_16 === msg.fields[0]), state.CheckedClaims) ? filter((x_17) => !(x_17 === msg.fields[0]), state.CheckedClaims) : append(state.CheckedClaims, singleton(msg.fields[0])), state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), Cmd_none()];
        default: {
            const patternInput = update_1(msg.fields[0], state.UserSearch);
            const componentIntent = patternInput[2];
            const componentCmd = patternInput[1];
            return [new State(patternInput[0], state.RoleSearch, state.SaveClaimsDeferred, state.SelectedUser, state.SelectedRole, state.CheckedClaims, state.GetClaimsDeferred, state.GetUsersDeferred, state.GetUserRoleDeferred), (componentIntent.tag === 1) ? Cmd_batch(ofArray([Cmd_map((Item_1) => (new Msg(4, [Item_1])), componentCmd), singleton((dispatch_1) => {
                dispatch_1(new Msg(1, [void 0]));
            })])) : ((componentIntent.tag === 2) ? Cmd_none() : Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(4, [Item])), componentCmd), singleton((dispatch) => {
                dispatch(new Msg(1, [componentIntent.fields[0]]));
            })])))];
        }
    }
}

export function render(state, dispatch) {
    let elems_16, elems_7, elems_1, elems, elems_6, elems_5, elems_4, elems_2, elems_3, elems_15, matchValue, elems_14;
    return createElement("div", createObj(singleton((elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_7 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "Roles and Custom Permissions",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: "",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [render_1(state.UserSearch, (arg) => {
        dispatch(new Msg(4, [arg]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_3 = [render_1(state.RoleSearch, (arg_1) => {
        dispatch(new Msg(5, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", {
        className: join(" ", ["column"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_15 = [(matchValue = state.GetClaimsDeferred, (matchValue.tag === 1) ? createElement("div", {
        children: "Getting claims...",
    }) : ((matchValue.tag === 2) ? createElement("div", createObj(singleton((elems_14 = toList(delay(() => append_1(map_1((item) => {
        let elems_12, elems_11, elems_8, elems_10, elems_9;
        return createElement("div", createObj(singleton((elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-narrow", "is-small"])], (elems_8 = [createElement("input", createObj(toList(delay(() => {
            let matchValue_1;
            return append_1(singleton_1((matchValue_1 = state.SelectedRole, (matchValue_1 != null) ? (!(matchValue_1.Id === "6") ? ["disabled", true] : ["disabled", false]) : ["disabled", true])), delay(() => append_1(singleton_1(["className", join(" ", ["column"])]), delay(() => append_1(singleton_1(["type", "checkbox"]), delay(() => append_1(exists_1((x_2) => (x_2 === FootPrintClaimModule_toInt(item)), state.CheckedClaims) ? singleton_1(["checked", true]) : empty_1(), delay(() => append_1(singleton_1(["onClick", (_arg) => {
                dispatch(new Msg(6, [FootPrintClaimModule_toInt(item)]));
            }]), delay(() => singleton_1(["name", FootPrintClaimModule_toString(item)])))))))))));
        }))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["label-is-small", "is-full-width"])], (elems_9 = [createElement("div", {
            children: FootPrintClaimModule_toString(item),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]))));
    }, filter((x) => (x !== 14), matchValue.fields[0].AllClaims)), delay(() => {
        let elems_13;
        return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_13 = [createElement("div", {
            className: join(" ", ["column"]),
        }), createElement("button", {
            className: join(" ", ["button", "is-primary", "is-small", "is-center"]),
            type: "button",
            children: "Save",
            onClick: (_arg_1) => {
                dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
            },
        }), createElement("div", {
            className: join(" ", ["column"]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])))) : createElement("div", {
        children: "",
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))]))));
}

