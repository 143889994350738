import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Blob_download, Http_overrideResponseType, Http_content, FormData_append, FormData_appendBlob, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { ResponseTypes, BodyContent, HttpMethod } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { PagedDataResponse$1_$reflection, AccountReportResponse_$reflection, ApiMessageResponseModule_mapMessages } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";
import { FileTypeModule_value } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { FSharpMap__TryGetValue } from "../fable_modules/fable-library.4.10.0/Map.js";
import { FSharpRef } from "../fable_modules/fable-library.4.10.0/Types.js";

export function search(page) {
    return singleton.Delay(() => {
        let req_2, req_1;
        return singleton.Bind(Http_send((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/AccountManagement?page=${page}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(AccountReportResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function upload(fileType, file) {
    return singleton.Delay(() => {
        let form, req_2;
        const formData = FormData_appendBlob("OrderFileUpload", file, (form = (new FormData()), FormData_append("OrderFileType", FileTypeModule_value(fileType), form)));
        return singleton.Bind(Http_send((req_2 = Http_content(new BodyContent(3, [formData]), Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/orders/upload`))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 202) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

export function downloadTemplate() {
    return singleton.Delay(() => {
        let req_1;
        return singleton.Bind(Http_send(Http_overrideResponseType(new ResponseTypes(1, []), (req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/AccountManagement/Template`)), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_1)))), (_arg) => {
            let matchValue_1, outArg;
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const matchValue = response.content;
                if (matchValue.tag === 1) {
                    const ResultValue = Blob_download(matchValue.fields[0], (matchValue_1 = ((outArg = defaultOf(), [FSharpMap__TryGetValue(response.responseHeaders, "file-name", new FSharpRef(() => outArg, (v) => {
                        outArg = v;
                    })), outArg])), matchValue_1[0] ? matchValue_1[1] : "Account-upload-template.xlsx"));
                    result = (new FSharpResult$2(0, [void 0]));
                }
                else {
                    result = (new FSharpResult$2(1, [ApiMessageResponseModule_mapMessages(["Failed to download the template"])]));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

