import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, unit_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { ApiMessageResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_texts, Toast_title, Toast_error } from "../Components/SweetAlert.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { login as login_1 } from "../API/AuthenticationAPI.fs.js";
import { createElement } from "react";
import { empty, append, singleton, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";

export class State extends Record {
    constructor(UserName, Password, SignInDeferred) {
        super();
        this.UserName = UserName;
        this.Password = Password;
        this.SignInDeferred = SignInDeferred;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.Login.State", [], State, () => [["UserName", string_type], ["Password", string_type], ["SignInDeferred", Deferred$1_$reflection(unit_type)]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserLoggedIn", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.Login.Intent", [], Intent, () => [[], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUserName", "SetPassword", "SignInAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.Login.Msg", [], Msg, () => [[["userName", string_type]], [["password", string_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]]]);
}

export function init() {
    return [new State("", "", new Deferred$1(0, [])), Cmd_none()];
}

export function update(msg, state) {
    let input_3;
    switch (msg.tag) {
        case 1:
            return [new State(state.UserName, msg.fields[0], state.SignInDeferred), Cmd_none(), new Intent(1, [])];
        case 2:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none(), new Intent(1, [])];
                case 2: {
                    const intent = (msg.fields[0].fields[0].tag === 1) ? (new Intent(1, [])) : (new Intent(0, []));
                    return [new State(state.UserName, state.Password, new Deferred$1(2, [void 0])), (input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Toast_error(Toast_title("Sign in", Toast_texts(ofArray(input_3.fields[0].Messages)))) : Cmd_none()), intent];
                }
                default:
                    if (isNullOrWhiteSpace(state.UserName) ? true : isNullOrWhiteSpace(state.Password)) {
                        return [state, Cmd_none(), new Intent(1, [])];
                    }
                    else if (equals(state.SignInDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none(), new Intent(1, [])];
                    }
                    else {
                        return [new State(state.UserName, state.Password, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, () => login_1(state.UserName, state.Password), void 0, (arg) => (new Msg(2, [new AsyncMsg$1(2, [arg])]))), new Intent(1, [])];
                    }
            }
        default:
            return [new State(msg.fields[0], state.Password, state.SignInDeferred), Cmd_none(), new Intent(1, [])];
    }
}

export function render(state, dispatch) {
    let elems_3;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-mobile", "is-centered", "is-vcentered"])], ["style", {
        height: 80 + "vh",
    }], (elems_3 = toList(delay(() => {
        let elems_2, elems_1, elems, value_24, value_37;
        return (state.SignInDeferred.tag === 1) ? singleton(render_1()) : singleton(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_1 = [createElement("form", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], ["onKeyPress", (keyPress) => {
            if (keyPress.key === "Enter") {
                dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
            }
        }], (elems = [createElement("img", {
            className: join(" ", ["image", "p-6"]),
            alt: "Crane Logo",
            src: "https://craneww-assets.azureedge.net/assets/crane-logo.svg",
        }), createElement("label", {
            children: "User Name",
        }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["disabled", equals(state.SignInDeferred, new Deferred$1(1, []))], ["type", "text"], ["autoComplete", "off"], ["placeholder", "Enter user name..."], (value_24 = state.UserName, ["ref", (e) => {
            if (!(e == null) && !equals(e.value, value_24)) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            dispatch(new Msg(0, [ev.target.value]));
        }]]))), createElement("br", {}), createElement("label", {
            children: "Password",
        }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["disabled", equals(state.SignInDeferred, new Deferred$1(1, []))], ["type", "password"], ["autoComplete", "off"], ["placeholder", "Enter password..."], (value_37 = state.Password, ["ref", (e_1) => {
            if (!(e_1 == null) && !equals(e_1.value, value_37)) {
                e_1.value = value_37;
            }
        }]), ["onChange", (ev_1) => {
            dispatch(new Msg(1, [ev_1.target.value]));
        }]]))), createElement("button", {
            className: join(" ", toList(delay(() => append(singleton("button"), delay(() => append(singleton("is-primary"), delay(() => append(singleton("is-small"), delay(() => append(singleton("my-4"), delay(() => append(singleton("px-4"), delay(() => append(singleton("py-2"), delay(() => (equals(state.SignInDeferred, new Deferred$1(1, [])) ? singleton("is-loading") : empty())))))))))))))))),
            type: "button",
            children: "Sign in",
            disabled: (isNullOrWhiteSpace(state.UserName) ? true : isNullOrWhiteSpace(state.Password)) ? true : equals(state.SignInDeferred, new Deferred$1(1, [])),
            onClick: (_arg) => {
                dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
            },
        }), createElement("a", {
            className: join(" ", ["mt-4", "has-text-centered", "is-link"]),
            href: RouterModule_encodeParts(singleton_1("/reset-password"), 1),
            children: "Forgot Password?",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

