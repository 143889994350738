import { filter, contains, exists, map, tryFind } from "../fable_modules/fable-library.4.10.0/List.js";
import { FootPrintClaimModule_toInt } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { comparePrimitives, numberHash } from "../fable_modules/fable-library.4.10.0/Util.js";
import { ofList, intersect, toList } from "../fable_modules/fable-library.4.10.0/Set.js";

export function userHasClaim(currentUser, claim) {
    const result = tryFind((x) => (x.ClaimId === FootPrintClaimModule_toInt(claim)), currentUser.Claims);
    if (result == null) {
        return false;
    }
    else {
        return result.Setting === true;
    }
}

export function userHasEitherClaim(currentUser, claims) {
    const currentUserClaimsAsInt = map((x) => x, claims);
    const subjectClaimsAsInt = map((x_1) => x_1.ClaimId, currentUser.Claims);
    return exists((x_2) => contains(x_2, subjectClaimsAsInt, {
        Equals: (x_3, y) => (x_3 === y),
        GetHashCode: numberHash,
    }), currentUserClaimsAsInt);
}

export function intersection(claimsList, layoutElements) {
    const claimsIntList = map((x) => x.ClaimId, claimsList);
    const layoutElementsIntList = map((x_1) => FootPrintClaimModule_toInt(x_1.Claim), layoutElements);
    const intersection_1 = toList(intersect(ofList(claimsIntList, {
        Compare: comparePrimitives,
    }), ofList(layoutElementsIntList, {
        Compare: comparePrimitives,
    })));
    return filter((x_4) => contains(FootPrintClaimModule_toInt(x_4.Claim), intersection_1, {
        Equals: (x_5, y_2) => (x_5 === y_2),
        GetHashCode: numberHash,
    }), layoutElements);
}

