import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { LookupResponseModule_ToSelectItems, ReportRequest, ApiMessageResponse_$reflection, SelectItem_$reflection, PagedDataResponse$1_$reflection, OnHandDetailsReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, int32_type, option_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractTypeAheadFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addTypeAheadSearchFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toTypeAheadSearchState, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsIdsAsStrings, StateSelectFilterHelper_selectedItemsDisplayValues, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { render as render_3, update as update_4, init as init_4, Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/ExportReportDialog.fs.js";
import { render as render_2, update as update_3, init as init_3, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_2 } from "../Components/SelectComponent.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { render as render_1, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_2 } from "../Components/MultiSelectSearch.fs.js";
import { render as render_4, update as update_2, extractName as extractName_1, init as init_2, Msg_$reflection as Msg_$reflection_3 } from "../Components/TypeAheadSearch.fs.js";
import { Default_pagedData, SearchFilterKey, DataSource_$reflection } from "../Models.fs.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Int32.js";
import { createObj, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getSupplierSKUs, getMaterials, getProjects } from "../API/DataSourceAPI.fs.js";
import { isEmpty, map, singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportOnHandDetails, getOnHandDetails } from "../API/ReportAPI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_5 } from "../Components/Loader.fs.js";
import { formatDateTime, formatDecimal } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, ExportDeferred, SelectFilters, ExportReportState, ShowProjectSearch, SelectedWarehouse, WarehouseSelectState, SupplierSelectState, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ExportReportState = ExportReportState;
        this.ShowProjectSearch = ShowProjectSearch;
        this.SelectedWarehouse = SelectedWarehouse;
        this.WarehouseSelectState = WarehouseSelectState;
        this.SupplierSelectState = SupplierSelectState;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.OnHandDetailsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection()))], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ExportReportState", State_$reflection_1()], ["ShowProjectSearch", bool_type], ["SelectedWarehouse", option_type(SelectItem_$reflection())], ["WarehouseSelectState", State_$reflection_2()], ["SupplierSelectState", State_$reflection_2()], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "ExportReportMsg", "MultiSelectSearchMsg", "SetSearchItems", "TypeAheadSearchMsg", "SetTypeAheadSearchItems", "SetSelectedWarehouses", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.OnHandDetailsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(OnHandDetailsReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", Msg_$reflection_2()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", Msg_$reflection_3()]], [["Item", DataSource_$reflection()]], [["Item", Msg_$reflection_4()]], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let matchValue_1;
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    const ProjectIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, [])));
    const MaterialIds = StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, [])));
    return new ReportRequest("OnHandDetails.xlsx", Description, ProjectIds, new Int32Array(0), (matchValue_1 = state.SelectedWarehouse, (matchValue_1 != null) ? (new Int32Array([parse(matchValue_1.Id, 511, false, 32)])) : (new Int32Array([]))), MaterialIds, new Int32Array([]), new Int32Array([]), [], new Int32Array([]), [], StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(7, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(8, []))), StateSelectFilterHelper_selectedItemsIdsAsStrings(state.SelectFilters, toString(new SearchFilterKey(29, []))), StateSelectFilterHelper_selectedItemsIdsAsStrings(state.SelectFilters, toString(new SearchFilterKey(10, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(12, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(13, []))), new Int32Array([]), [], [], "", "None", "None", defaultOf(), "", "", defaultOf(), defaultOf(), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if ((((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.MaterialIds.length === 0)) ? true : !(request.SupplierSKUIds.length === 0)) ? true : !(request.AWSSKUIds.length === 0)) ? true : !(request.CartonIds.length === 0)) ? true : !(request.PalletIds.length === 0)) ? true : !(request.ASNReferenceIds.length === 0)) ? true : !(request.ReservationIds.length === 0)) {
        return true;
    }
    else {
        return !(request.WarehouseIds.length === 0);
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToTypeAheadSearch(typeHeldState) {
    return TypeStateHeldModule_toTypeAheadSearchState(typeHeldState);
}

export function init(user) {
    let ShowProjectSearch;
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(2, []), (searchCriteria_1) => getMaterials(user, searchCriteria_1));
    const materialSearchCmd = patternInput_1[1];
    const patternInput_2 = init_1(new SearchFilterKey(10, []), (searchCriteria_2) => getSupplierSKUs(user, searchCriteria_2));
    const patternInput_3 = init_2(toString(new SearchFilterKey(7, [])), 5);
    const patternInput_4 = init_2(toString(new SearchFilterKey(8, [])), 8);
    const patternInput_5 = init_2(toString(new SearchFilterKey(29, [])), 23);
    const patternInput_6 = init_3(toString(new SearchFilterKey(10, [])), LookupResponseModule_ToSelectItems(user.SupplierSKUs), "Choose Supplier ..");
    const patternInput_7 = init_3(toString(new SearchFilterKey(3, [])), LookupResponseModule_ToSelectItems(user.Warehouses), "Choose Warehouse ..");
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput_1[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(10, []), patternInput_2[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(7, []), patternInput_3[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(8, []), patternInput_4[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(29, []), patternInput_5[0])));
    const patternInput_8 = init_4("Export");
    return [(ShowProjectSearch = !(user.Role === 3), new State(new Deferred$1(0, []), new Deferred$1(0, []), ofArray(selectFilters.slice()), patternInput_8[0], ShowProjectSearch, void 0, patternInput_7[0], patternInput_6[0], 1)), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(3, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(3, [Item_1])), materialSearchCmd), Cmd_map((Item_2) => (new Msg(3, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(3, [Item_3])), materialSearchCmd), Cmd_map((Item_4) => (new Msg(3, [Item_4])), materialSearchCmd), patternInput_8[1]]))];
}

export function update(msg, state) {
    let input_3;
    switch (msg.tag) {
        case 8:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page + 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 9:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page - 1), singleton((dispatch_2) => {
                    dispatch_2(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_3;
                    const input_1 = msg.fields[0].fields[0];
                    command_3 = ((input_1.tag === 1) ? Alert_error(Alert_title("On Hand Details Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_3];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(new Deferred$1(0, []), state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getOnHandDetails, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [void 0]), state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportOnHandDetails, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 3: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput_1 = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent_1 = patternInput_1[2];
                const componentCmd_1 = patternInput_1[1];
                const command_6 = (componentIntent_1.tag === 1) ? Cmd_map((Item_5) => (new Msg(3, [Item_5])), componentCmd_1) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(3, [Item_4])), componentCmd_1), singleton((dispatch_3) => {
                    dispatch_3(new Msg(4, [componentIntent_1.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_3) => {
                    if (toString(x_3.Id) === filterId) {
                        return new StateSelectFilter(x_3.Id, new TypeStateHeld(0, [patternInput_1[0]]), x_3.SelectedItems);
                    }
                    else {
                        return x_3;
                    }
                }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_6];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 4:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_5) => {
                if (toString(x_5.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_5.Id, x_5.State, msg.fields[0].Items);
                }
                else {
                    return x_5;
                }
            }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, isEmpty(msg.fields[0].Items) ? state.Page : 1), singleton((dispatch_4) => {
                dispatch_4(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 5: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const typeAheadFilter = StateSelectFilterHelper_extractTypeAheadFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (typeAheadFilter != null) {
                const patternInput_2 = update_2(msg.fields[0], typeAheadFilter);
                const componentIntent_2 = patternInput_2[2];
                const componentCmd_2 = patternInput_2[1];
                const command_7 = (componentIntent_2.tag === 1) ? Cmd_map((Item_7) => (new Msg(5, [Item_7])), componentCmd_2) : Cmd_batch(ofArray([Cmd_map((Item_6) => (new Msg(5, [Item_6])), componentCmd_2), singleton((dispatch_5) => {
                    dispatch_5(new Msg(6, [componentIntent_2.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_6) => {
                    if (toString(x_6.Id) === filterId_1) {
                        return new StateSelectFilter(x_6.Id, new TypeStateHeld(1, [patternInput_2[0]]), x_6.SelectedItems);
                    }
                    else {
                        return x_6;
                    }
                }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), command_7];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 6:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_8) => {
                if (toString(x_8.Id) === msg.fields[0].SearchDataId) {
                    return new StateSelectFilter(x_8.Id, x_8.State, ofArray(msg.fields[0].Data));
                }
                else {
                    return x_8;
                }
            }, state.SelectFilters), state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, isEmpty(ofArray(msg.fields[0].Data)) ? state.Page : 1), singleton((dispatch_6) => {
                dispatch_6(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 7: {
            const patternInput_3 = update_3(msg.fields[0], state.WarehouseSelectState);
            const componentState_3 = patternInput_3[0];
            const componentIntent_3 = patternInput_3[2];
            const componentCmd_3 = patternInput_3[1];
            switch (componentIntent_3.tag) {
                case 0:
                    return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, componentIntent_3.fields[0], componentState_3, state.SupplierSelectState, state.Page), Cmd_batch(ofArray([singleton((dispatch_8) => {
                        dispatch_8(new Msg(0, [new AsyncMsg$1(0, [])]));
                    }), componentCmd_3]))];
                case 2:
                    return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, state.SelectedWarehouse, componentState_3, state.SupplierSelectState, state.Page), componentCmd_3];
                default:
                    return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ExportReportState, state.ShowProjectSearch, void 0, componentState_3, state.SupplierSelectState, state.Page), Cmd_batch(ofArray([singleton((dispatch_7) => {
                        dispatch_7(new Msg(0, [new AsyncMsg$1(0, [])]));
                    }), componentCmd_3]))];
            }
        }
        default:
            if (!hasFilters(state)) {
                return [state, Toast_info(Toast_title("Invalid Option", Toast_text("Cannot export a report without filters")))];
            }
            else {
                const patternInput = update_4(msg.fields[0], state.ExportReportState);
                const componentState = patternInput[0];
                const componentIntent = patternInput[2];
                switch (componentIntent.tag) {
                    case 1:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                    case 2:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), Cmd_none()];
                    default:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, componentState, state.ShowProjectSearch, state.SelectedWarehouse, state.WarehouseSelectState, state.SupplierSelectState, state.Page), singleton((dispatch) => {
                            dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
                        })];
                }
            }
    }
}

export function renderTop(state, dispatch) {
    let elems_2, elems_1, elems, matchValue, pagedData;
    return createElement("div", createObj(singleton((elems_2 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "On Hand Details Query",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
}

export function renderRow1(state, dispatch) {
    let elems_9;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = toList(delay(() => {
        let elems;
        return append(state.ShowProjectSearch ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(3, [arg]));
        }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [render_2(state.WarehouseSelectState, (arg_1) => {
                dispatch(new Msg(7, [arg_1]));
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_8, elems_7, elems_3, elems_2, elems_5, elems_4, elems_6;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_7 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                    dispatch(new Msg(9, []));
                }], (elems_3 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_2 = [createElement("span", {
                    children: ["navigate_before"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Previous",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                    dispatch(new Msg(8, []));
                }], (elems_5 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_4 = [createElement("span", {
                    children: ["navigate_next"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Next",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(singleton((elems_6 = [render_3(state.ExportReportState, (arg_2) => {
                    dispatch(new Msg(2, [arg_2]));
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])));
}

export function renderRow2(state, dispatch) {
    let elems_5, elems_4, elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_5 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(3, [arg]));
    }), new SearchFilterKey(10, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_4(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(5, [arg_1]));
    }), new SearchFilterKey(7, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_4(convertStateToTypeAheadSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(5, [arg_2]));
    }), new SearchFilterKey(8, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function renderRow3(state, dispatch) {
    let elems_3, elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_4(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(5, [arg]));
    }), new SearchFilterKey(29, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderReport(state, dispatch) {
    let elems_5;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_5 = toList(delay(() => {
        let elems_4, elems_3, elems, children, elems_2;
        const matchValue = state.PagedDataDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton_1(render_5());
            case 2: {
                const pagedData = matchValue.fields[0];
                if (pagedData.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No items were found that meet the search criteria",
                    }));
                }
                else {
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", ["px-3", "py-2"]),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_4 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_3 = [createElement("thead", createObj(singleton((elems = [(children = ofArray([tableHeader("Warehouse"), tableHeader("Supplier ID"), tableHeader("Supplier"), tableHeader("AWS SKU"), tableHeader("Supplier SKU"), tableHeader("SKU Description"), tableHeader("UOM"), tableHeader("Location"), tableHeader("Carton ID"), tableHeader("Pallet ID"), tableHeader("Qty"), tableHeader("Qty Allocated"), tableHeader("Qty Picked"), tableHeader("Qty Hold"), tableHeader("Qty Available"), tableHeader("Receipt"), tableHeader("Date Received"), tableHeader("ASN Reference"), tableHeader("COO"), tableHeader("ODM ID"), tableHeader("Reservation ID"), tableHeader("Date Reserved")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), createElement("tbody", createObj(singleton((elems_2 = toList(delay(() => map_1((item) => {
                        let elems_1;
                        return createElement("tr", createObj(singleton((elems_1 = [tableData(item.WarehouseName), tableData(item.SupplierId), tableData(item.Supplier), tableData(item.AwsSku), tableData(item.SupplierSku), tableData(item.SkuDescription), tableData(item.Uom), tableData(item.Location), tableData(item.CartonId), tableData(item.PalletId), tableData(formatDecimal(item.Quantity)), tableData(formatDecimal(item.QuantityAllocated)), tableData(formatDecimal(item.QuantityPicked)), tableData(formatDecimal(item.QuantityOnHold)), tableData(formatDecimal(item.QuantityAvailable)), tableData(item.Receipt), tableData(formatDateTime(item.DateReceived)), tableData(item.AsnReference), tableData(item.Coo), tableData(item.OdmId), tableData(item.ReservationId), tableData(formatDateTime(item.DateReserved))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
                    }, pagedData.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Select filters to run the query",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
}

export function render(state, dispatch) {
    let elems_3, elems_1, elems, elems_2;
    return createElement("div", createObj(singleton((elems_3 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
        className: join(" ", ["has-link"]),
        href: "#/scheduled-reports",
        children: "Scheduled Reports",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_2 = [renderTop(state, dispatch), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), renderReport(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]))));
}

