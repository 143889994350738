import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Http_withTimeout, Headers_authorization, Http_header, Http_request, Http_method, Http_content, Http_send, FormData_append, FormData_appendBlob } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { FileTypeModule_value } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function upload(fileType, file) {
    return singleton.Delay(() => {
        let form, req_3, req_2;
        const formData = FormData_appendBlob("OrderFileUpload", file, (form = (new FormData()), FormData_append("OrderFileType", FileTypeModule_value(fileType), form)));
        return singleton.Bind(Http_send((req_3 = ((req_2 = Http_content(new BodyContent(3, [formData]), Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/orders/upload`))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            const response = _arg;
            const result = (response.statusCode === 202) ? (new FSharpResult$2(0, [void 0])) : tryParseAsApiMessage(response);
            return singleton.Return(result);
        });
    });
}

