import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { ofArray, filter } from "../fable_modules/fable-library.4.10.0/List.js";
import { Blob_download, Http_overrideResponseType, Http_withTimeout, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { ResponseTypes, HttpMethod } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { getIdToken } from "./AuthenticationAPI.fs.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { ShippingOrderDetailResponse_$reflection, PagedDataResponse$1_$reflection, ShippingOrderResponse_$reflection, ApiMessageResponseModule_mapMessages } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";
import { array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { FSharpMap__TryGetValue } from "../fable_modules/fable-library.4.10.0/Map.js";
import { FSharpRef } from "../fable_modules/fable-library.4.10.0/Types.js";

export function search(orderStatus, page) {
    return singleton.Delay(() => {
        let req_3, req_2, req_1;
        const queryParams = join("&", filter((arg) => !isNullOrWhiteSpace(arg), ofArray([`page=${page}`, (orderStatus != null) ? (`orderStatus=${orderStatus}`) : ""])));
        return singleton.Bind(Http_send((req_3 = ((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/shippingOrders?${queryParams}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(ShippingOrderResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function getDetails(shippingOrderId, ownerType) {
    return singleton.Delay(() => {
        let req_3, req_2, req_1;
        return singleton.Bind(Http_send((req_3 = ((req_2 = ((req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/shippingOrders/${shippingOrderId}/details?ownerType=${ownerType}`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const response_2 = response;
                const toGeneralError = (error) => {
                    console.error(some("API error"), error);
                    return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
                };
                if (isNullOrWhiteSpace(response_2.responseText)) {
                    result = (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")]));
                }
                else {
                    let input_4;
                    const input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText));
                    try {
                        input_4 = (new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(array_type(ShippingOrderDetailResponse_$reflection())))]));
                    }
                    catch (ex) {
                        input_4 = (new FSharpResult$2(1, [ex.message]));
                    }
                    result = ((input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]])));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function downloadTemplate() {
    return singleton.Delay(() => {
        let req_3, req_1;
        return singleton.Bind(Http_send((req_3 = Http_overrideResponseType(new ResponseTypes(1, []), (req_1 = Http_method(new HttpMethod(0, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/shippingOrders/template`)), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_1))), Http_withTimeout(process.env.HTTP_REQUEST_TIMEOUT ? process.env.HTTP_REQUEST_TIMEOUT : '100000', req_3))), (_arg) => {
            let matchValue_1, outArg;
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const matchValue = response.content;
                if (matchValue.tag === 1) {
                    const ResultValue = Blob_download(matchValue.fields[0], (matchValue_1 = ((outArg = defaultOf(), [FSharpMap__TryGetValue(response.responseHeaders, "file-name", new FSharpRef(() => outArg, (v) => {
                        outArg = v;
                    })), outArg])), matchValue_1[0] ? matchValue_1[1] : "940-upload-template.xlsx"));
                    result = (new FSharpResult$2(0, [void 0]));
                }
                else {
                    result = (new FSharpResult$2(1, [ApiMessageResponseModule_mapMessages(["Failed to download the template"])]));
                }
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

