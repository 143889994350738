import sweetalert2 from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { singleton as singleton_1, ofArray, length } from "../fable_modules/fable-library.4.10.0/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { awaitPromise, startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";

export const Interop_swal = sweetalert2;

function Interop_fire(x) {
    return Interop_swal.fire(x);
}

export function Alert_defaultOptions() {
    const options = {};
    options.customClass = {};
    options.customClass["confirmButton"] = "button is-small mx-2 px-5 is-primary";
    options.customClass["cancelButton"] = "button is-small mx-2 px-4";
    options.customClass["title"] = "is-size-6";
    options.customClass["htmlContainer"] = "is-size-7";
    options["buttonsStyling"] = false;
    return options;
}

export function Alert_text(value) {
    const options = Alert_defaultOptions();
    options["text"] = value;
    return options;
}

export function Alert_texts(messages) {
    let elems;
    const value_5 = renderToStaticMarkup(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("m-3"), delay(() => append(singleton("is-flex"), delay(() => append(singleton("is-flex-direction-column"), delay(() => ((length(messages) > 1) ? singleton("is-align-items-start") : empty()))))))))))], (elems = toList(delay(() => map((message) => createElement("div", {
        className: join(" ", ["my-1"]),
        children: message,
    }), messages))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    const options = Alert_defaultOptions();
    options["html"] = value_5;
    return options;
}

export function Alert_textsConfirmCancel(messages) {
    let elems;
    const value_5 = renderToStaticMarkup(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("m-3"), delay(() => append(singleton("is-flex"), delay(() => append(singleton("is-flex-direction-column"), delay(() => ((length(messages) > 1) ? singleton("is-align-items-start") : empty()))))))))))], (elems = toList(delay(() => map((message) => createElement("div", {
        className: join(" ", ["my-1"]),
        children: message,
    }), messages))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    const options = Alert_defaultOptions();
    options["showCancelButton"] = "true";
    options["showConfirmButton"] = "true";
    options["confirmButtonText"] = "Confirm";
    options["cancelButtonText"] = "Cancel";
    options["allowOutsideClick"] = false;
    options["html"] = value_5;
    return options;
}

export function Alert_title(value, options) {
    options["title"] = value;
    return options;
}

export function Alert_success(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "success";
        Interop_fire(options);
    });
}

export function Alert_error(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "error";
        Interop_fire(options);
    });
}

export function Alert_warning(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "warning";
        Interop_fire(options);
    });
}

export function Alert_info(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "info";
        Interop_fire(options);
    });
}

export function Alert_question(msg, options) {
    return singleton_1((dispatch) => {
        startImmediate(singleton_2.Delay(() => {
            options["showCancelButton"] = true;
            options["icon"] = "question";
            return singleton_2.Bind(awaitPromise(Interop_fire(options)), (_arg) => {
                if (_arg["value"]) {
                    dispatch(msg);
                    return singleton_2.Zero();
                }
                else {
                    return singleton_2.Zero();
                }
            });
        }));
    });
}

export function Alert_questionWithConfirmOrCancel(confirmMsg, cancelMsg, options) {
    return singleton_1((dispatch) => {
        startImmediate(singleton_2.Delay(() => {
            options["showCancelButton"] = true;
            options["icon"] = "question";
            return singleton_2.Bind(awaitPromise(Interop_fire(options)), (_arg) => {
                if (_arg["value"]) {
                    dispatch(confirmMsg);
                    return singleton_2.Zero();
                }
                else {
                    dispatch(cancelMsg);
                    return singleton_2.Zero();
                }
            });
        }));
    });
}

function Toast_defaultOptions() {
    const options = {};
    options.customClass = {};
    options.customClass["title"] = "is-size-6";
    options.customClass["htmlContainer"] = "is-size-7";
    options["timer"] = 5000;
    options["timerProgressBar"] = true;
    options["showConfirmButton"] = false;
    options["showCancelButton"] = false;
    options["toast"] = true;
    options["position"] = "bottom";
    return options;
}

export function Toast_text(value) {
    const options = Toast_defaultOptions();
    options["text"] = value;
    return options;
}

export function Toast_texts(messages) {
    let elems;
    const value_5 = renderToStaticMarkup(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("m-3"), delay(() => append(singleton("is-flex"), delay(() => append(singleton("is-flex-direction-column"), delay(() => ((length(messages) > 1) ? singleton("is-align-items-start") : empty()))))))))))], (elems = toList(delay(() => map((message) => createElement("div", {
        className: join(" ", ["my-1"]),
        children: message,
    }), messages))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    const options = Toast_defaultOptions();
    options["html"] = value_5;
    return options;
}

export function Toast_title(value, options) {
    options["title"] = value;
    return options;
}

export function Toast_success(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "success";
        Interop_fire(options);
    });
}

export function Toast_error(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "error";
        Interop_fire(options);
    });
}

export function Toast_warning(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "warning";
        Interop_fire(options);
    });
}

export function Toast_info(options) {
    return singleton_1((dispatch) => {
        options["icon"] = "info";
        Interop_fire(options);
    });
}

