import { Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { string_type, record_type, option_type, bool_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DataSourceItem_$reflection } from "../Models.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { tryFind, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { orderTypeInbound, orderTypeOutbound, datexOrderStatus } from "../API/DataSourceAPI.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { parse } from "../fable_modules/fable-library.4.10.0/Int32.js";
import { map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";

export class OrderTypeInboundOrOutbound extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Inbound", "Outbound"];
    }
}

export function OrderTypeInboundOrOutbound_$reflection() {
    return union_type("FootPrint.Portal.UI.OrderTypeStatus.OrderTypeStatus.OrderTypeInboundOrOutbound", [], OrderTypeInboundOrOutbound, () => [[], []]);
}

export class State extends Record {
    constructor(Disabled, OrderType, OrderStatus, InboundOrOutboundOrderType) {
        super();
        this.Disabled = Disabled;
        this.OrderType = OrderType;
        this.OrderStatus = OrderStatus;
        this.InboundOrOutboundOrderType = InboundOrOutboundOrderType;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.OrderTypeStatus.OrderTypeStatus.State", [], State, () => [["Disabled", bool_type], ["OrderType", option_type(DataSourceItem_$reflection())], ["OrderStatus", option_type(DataSourceItem_$reflection())], ["InboundOrOutboundOrderType", OrderTypeInboundOrOutbound_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetOrderStatus", "SetOrderType"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.OrderTypeStatus.OrderTypeStatus.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OrderStatusChanged", "OrderTypeChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.OrderTypeStatus.OrderTypeStatus.Intent", [], Intent, () => [[["Item", option_type(DataSourceItem_$reflection())]], [["Item", option_type(DataSourceItem_$reflection())]], []]);
}

export function init(inOrOutbound) {
    return [new State(false, void 0, void 0, inOrOutbound), Cmd_batch(ofArray([singleton((dispatch) => {
        dispatch(new Msg(0, [""]));
    }), singleton((dispatch_1) => {
        dispatch_1(new Msg(1, [""]));
    })]))];
}

export function update(msg, state) {
    if (msg.tag === 0) {
        const dataSourceItemList_1 = tryFind((x_1) => (x_1.Id === msg.fields[0]), datexOrderStatus);
        return [new State(state.Disabled, state.OrderType, dataSourceItemList_1, state.InboundOrOutboundOrderType), Cmd_none(), new Intent(0, [dataSourceItemList_1])];
    }
    else {
        const dataSourceItemList = tryFind((x) => (x.Id === msg.fields[0]), (state.InboundOrOutboundOrderType.tag === 1) ? orderTypeOutbound : orderTypeInbound);
        return [new State(state.Disabled, dataSourceItemList, state.OrderStatus, state.InboundOrOutboundOrderType), Cmd_none(), new Intent(1, [dataSourceItemList])];
    }
}

export function renderOrderType(state, dispatch) {
    let elems_2, elems_1, matchValue, elems;
    return createElement("div", createObj(ofArray([["className", "column"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_1 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.value]));
    }], ["value", (matchValue = state.OrderType, (matchValue != null) ? parse(matchValue.Id, 511, false, 32) : 0)], (elems = toList(delay(() => append(singleton_1(createElement("option", {
        children: "-- Order Type --",
        value: "",
    })), delay(() => map((item) => createElement("option", {
        children: item.DisplayValue,
        value: item.Id,
    }), (state.InboundOrOutboundOrderType.tag === 1) ? orderTypeOutbound : orderTypeInbound))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function renderOrderStatus(state, dispatch) {
    let elems_2, elems_1, matchValue, elems;
    return createElement("div", createObj(ofArray([["className", "column"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_1 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }], ["value", (matchValue = state.OrderStatus, (matchValue != null) ? parse(matchValue.Id, 511, false, 32) : 0)], (elems = toList(delay(() => append(singleton_1(createElement("option", {
        children: "-- Order Status --",
        value: 0,
    })), delay(() => map((item) => createElement("option", {
        children: item.DisplayValue,
        value: item.Id,
    }), datexOrderStatus))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function render(state, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "columns"], (elems = [renderOrderType(state, dispatch), renderOrderStatus(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

