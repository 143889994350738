import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, option_type, string_type, record_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { isNullOrWhiteSpace, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";

export class IsModalActiveMsgData extends Record {
    constructor(Show) {
        super();
        this.Show = Show;
    }
}

export function IsModalActiveMsgData_$reflection() {
    return record_type("FootPrint.Portal.UI.ExportReportDialog.ExportReportDialog.IsModalActiveMsgData", [], IsModalActiveMsgData, () => [["Show", bool_type]]);
}

export class State extends Record {
    constructor(Name, IsModalActive, OkButtonActive, ReportDescription) {
        super();
        this.Name = Name;
        this.IsModalActive = IsModalActive;
        this.OkButtonActive = OkButtonActive;
        this.ReportDescription = ReportDescription;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.ExportReportDialog.ExportReportDialog.State", [], State, () => [["Name", string_type], ["IsModalActive", IsModalActiveMsgData_$reflection()], ["OkButtonActive", bool_type], ["ReportDescription", option_type(string_type)]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Description", "Canceled", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.ExportReportDialog.ExportReportDialog.Intent", [], Intent, () => [[["Item", string_type]], [], []]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsModalActive", "SetOkButtonActive", "SetDescription", "OkButtonClicked", "CancelButtonClicked"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.ExportReportDialog.ExportReportDialog.Msg", [], Msg, () => [[["isModalActive", IsModalActiveMsgData_$reflection()]], [["isOkButtonActive", bool_type]], [["Item", option_type(string_type)]], [], []]);
}

export function init(name) {
    return [new State(name, new IsModalActiveMsgData(false), false, void 0), Cmd_none()];
}

export function update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1:
            return [new State(state.Name, state.IsModalActive, msg.fields[0], state.ReportDescription), Cmd_none(), new Intent(0, [(matchValue = state.ReportDescription, (matchValue != null) ? matchValue : "")])];
        case 2: {
            const newDescription = msg.fields[0];
            if (newDescription == null) {
                return [state, Cmd_none(), new Intent(2, [])];
            }
            else {
                return [new State(state.Name, state.IsModalActive, state.OkButtonActive, newDescription), Cmd_none(), new Intent(2, [])];
            }
        }
        case 3: {
            let setDescription;
            const matchValue_1 = state.ReportDescription;
            setDescription = ((matchValue_1 != null) ? matchValue_1 : "");
            return [state, Cmd_none(), new Intent(0, [setDescription])];
        }
        case 4:
            return [new State(state.Name, new IsModalActiveMsgData(false), state.OkButtonActive, void 0), Cmd_none(), new Intent(2, [])];
        default:
            return [new State(state.Name, msg.fields[0], state.OkButtonActive, state.ReportDescription), Cmd_none(), new Intent(2, [])];
    }
}

export function render(state, dispatch) {
    let elems_10, elems_9, elems_1, elems, elems_7, elems_3, elems_2, elems_6, elems_4, elems_5, elems_8, elems_14, elems_13, elems_12, elems_11;
    const modal = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("modal"), delay(() => append(singleton("px-4"), delay(() => (state.IsModalActive.Show ? singleton("is-active") : empty()))))))))], (elems_10 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", "modal-card"], (elems_9 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems_1 = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: "Schedule Report",
    }), createElement("span", createObj(ofArray([["className", join(" ", ["material-icons"])], (elems = [createElement("i", {
        children: ["check"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("section", createObj(ofArray([["className", join(" ", ["modal-card-body"])], (elems_7 = [createElement("div", {
        className: "block",
    }), createElement("div", createObj(ofArray([["className", "columns is-centered"], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-full-width"])], (elems_2 = [createElement("div", {
        className: join(" ", ["has-text-centered", "is-size-6"]),
        children: "Enter a description. This will appear on the Scheduled Reports page.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", {
        className: join(" ", ["label-is-small"]),
        children: "Description",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-two-thirds"])], (elems_5 = [createElement("input", {
        className: join(" ", ["input", "is-small"]),
        onChange: (e) => {
            const element = e.target;
            if (isNullOrWhiteSpace(element.value)) {
                dispatch(new Msg(2, [void 0]));
            }
            else {
                dispatch(new Msg(2, [element.value]));
            }
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_8 = [createElement("button", {
        className: join(" ", ["button", "is-primary", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Ok",
        onClick: (_arg) => {
            dispatch(new Msg(0, [new IsModalActiveMsgData(false)]));
            dispatch(new Msg(3, []));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_1) => {
            dispatch(new Msg(4, []));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
    return createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_14 = [modal, createElement("p", createObj(ofArray([["className", join(" ", ["control"])], (elems_13 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["onClick", (_arg_2) => {
        dispatch(new Msg(0, [new IsModalActiveMsgData(true)]));
    }], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_11 = [createElement("i", {
        children: ["file_download"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("span", {
        children: "Schedule Report",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])])));
}

