import { toString, Record, Union } from "../fable_modules/fable-library.4.10.0/Types.js";
import { Msg_$reflection, State_$reflection } from "../Components/MultiSelectSearch.fs.js";
import { Msg_$reflection as Msg_$reflection_1, State_$reflection as State_$reflection_1 } from "../Components/TypeAheadSearch.fs.js";
import { State_$reflection as State_$reflection_2 } from "../Components/SelectComponent.fs.js";
import { record_type, list_type, union_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { DataSourceItem_$reflection, SearchFilterKey_$reflection } from "../Models.fs.js";
import { choose, toArray, find, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { value as value_2 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SystemError } from "./ErrorHandlingHelper.fs.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";

export class TypeStateHeld extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MultiSelectSearch", "TypeAheadSearch", "DropDownSearch"];
    }
}

export function TypeStateHeld_$reflection() {
    return union_type("FootPrint.Portal.UI.Utilities.StateSelectFilterHelper.TypeStateHeld", [], TypeStateHeld, () => [[["Item", State_$reflection()]], [["Item", State_$reflection_1()]], [["Item", State_$reflection_2()]]]);
}

export class TypeStateHeldModule_SearchTypeMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MultiSelectSearchMsg", "TypeAheadSearchMsg"];
    }
}

export function TypeStateHeldModule_SearchTypeMsg_$reflection() {
    return union_type("FootPrint.Portal.UI.Utilities.StateSelectFilterHelper.TypeStateHeldModule.SearchTypeMsg", [], TypeStateHeldModule_SearchTypeMsg, () => [[["Item", Msg_$reflection()]], [["Item", Msg_$reflection_1()]]]);
}

export function TypeStateHeldModule_toMultiSearchState(typeStateHeld) {
    if (typeStateHeld.tag === 0) {
        return typeStateHeld.fields[0];
    }
    else {
        throw new Error("Tried to convert a TypeStateHeld to a MultiSearch.State when it is another type");
    }
}

export function TypeStateHeldModule_toTypeAheadSearchState(typeStateHeld) {
    if (typeStateHeld.tag === 1) {
        return typeStateHeld.fields[0];
    }
    else {
        throw new Error("Tried to convert a TypeStateHeld to a TypeAheadSearch.State when it is another type");
    }
}

export function TypeStateHeldModule_toDropDownSearch(typeStateHeld) {
    if (typeStateHeld.tag === 2) {
        return typeStateHeld.fields[0];
    }
    else {
        throw new Error("Tried to convert a TypeStateHeld to a DropDownSearch.State when it is another type");
    }
}

export class StateSelectFilter extends Record {
    constructor(Id, State, SelectedItems) {
        super();
        this.Id = Id;
        this.State = State;
        this.SelectedItems = SelectedItems;
    }
}

export function StateSelectFilter_$reflection() {
    return record_type("FootPrint.Portal.UI.Utilities.StateSelectFilterHelper.StateSelectFilter", [], StateSelectFilter, () => [["Id", SearchFilterKey_$reflection()], ["State", TypeStateHeld_$reflection()], ["SelectedItems", list_type(DataSourceItem_$reflection())]]);
}

export function StateSelectFilterModule_addMultiSearchFilter(id, state) {
    return new StateSelectFilter(id, new TypeStateHeld(0, [state]), empty());
}

export function StateSelectFilterModule_addTypeAheadSearchFilter(id, state) {
    return new StateSelectFilter(id, new TypeStateHeld(1, [state]), empty());
}

export function StateSelectFilterModule_addDropDownFilter(id, state) {
    return new StateSelectFilter(id, new TypeStateHeld(2, [state]), empty());
}

export function StateSelectFilterHelper_tryGet(stateSelectFilters, key) {
    try {
        return find((x) => (toString(x.Id) === key), stateSelectFilters);
    }
    catch (ex) {
        return void 0;
    }
}

export function StateSelectFilterHelper_extractMultiSelectFilter(subjectFilter) {
    const displayErrors = void Alert_error(Alert_title("System Error", Alert_text("Something went wrong. Please contact the system administrator.")));
    if (subjectFilter == null) {
        return void 0;
    }
    else {
        const matchValue = subjectFilter.State;
        if (matchValue.tag === 0) {
            return matchValue.fields[0];
        }
        else {
            return void 0;
        }
    }
}

export function StateSelectFilterHelper_extractTypeAheadFilter(subjectFilter) {
    const displayErrors = void Alert_error(Alert_title("System Error", Alert_text("Something went wrong. Please contact the system administrator.")));
    if (subjectFilter == null) {
        return void 0;
    }
    else {
        const matchValue = subjectFilter.State;
        if (matchValue.tag === 1) {
            return matchValue.fields[0];
        }
        else {
            return void 0;
        }
    }
}

export function StateSelectFilterHelper_extractDropDownFilter(subjectFilter) {
    const displayErrors = void Alert_error(Alert_title("System Error", Alert_text("Something went wrong. Please contact the system administrator.")));
    if (subjectFilter == null) {
        return void 0;
    }
    else {
        const matchValue = subjectFilter.State;
        if (matchValue.tag === 2) {
            return matchValue.fields[0];
        }
        else {
            return void 0;
        }
    }
}

export function StateSelectFilterHelper_renderSearch(stateSelectFilters, action, searchFilterId) {
    return action(value_2(StateSelectFilterHelper_tryGet(stateSelectFilters, toString(searchFilterId))));
}

export function StateSelectFilterHelper_renderTypeAheadSearch(stateSelectFilters, action, searchFilterId) {
    return action(value_2(StateSelectFilterHelper_tryGet(stateSelectFilters, toString(searchFilterId))));
}

export function StateSelectFilterHelper_selectedItemsDisplayValues(stateSelectFilters, searchFilterId) {
    const selectedFilter = StateSelectFilterHelper_tryGet(stateSelectFilters, searchFilterId);
    if (selectedFilter != null) {
        const matchValue = selectedFilter.State;
        switch (matchValue.tag) {
            case 1:
                return toArray(choose((x_3) => x_3.DisplayValue, matchValue.fields[0].SelectedItems));
            case 2: {
                SystemError("Cannot get multiple values for DropDownSearch");
                return [];
            }
            default:
                return toArray(choose((x_1) => x_1.DisplayValue, matchValue.fields[0].SelectedItems));
        }
    }
    else {
        SystemError(`Unknown stateFilter [${searchFilterId}]`);
        return [];
    }
}

export function StateSelectFilterHelper_selectedItemsIds(stateSelectFilters, searchFilterId) {
    const selectedFilter = StateSelectFilterHelper_tryGet(stateSelectFilters, searchFilterId);
    if (selectedFilter != null) {
        const matchValue = selectedFilter.State;
        switch (matchValue.tag) {
            case 1:
                return toArray(choose((x_3) => Int32_fromString(x_3.Id), matchValue.fields[0].SelectedItems));
            case 2: {
                SystemError("Cannot get multiple values for DropDownSearch");
                return new Int32Array([]);
            }
            default:
                return toArray(choose((x_1) => Int32_fromString(x_1.Id), matchValue.fields[0].SelectedItems));
        }
    }
    else {
        SystemError(`Unknown stateFilter [${searchFilterId}]`);
        return new Int32Array([]);
    }
}

export function StateSelectFilterHelper_selectedItemsIdsAsStrings(stateSelectFilters, searchFilterId) {
    const selectedFilter = StateSelectFilterHelper_tryGet(stateSelectFilters, searchFilterId);
    if (selectedFilter != null) {
        const matchValue = selectedFilter.State;
        switch (matchValue.tag) {
            case 1:
                return toArray(choose((x_3) => x_3.Id, matchValue.fields[0].SelectedItems));
            case 2: {
                SystemError("Cannot get multiple values for DropDownSearch");
                return [];
            }
            default:
                return toArray(choose((x_1) => x_1.Id, matchValue.fields[0].SelectedItems));
        }
    }
    else {
        SystemError(`Unknown stateFilter [${searchFilterId}]`);
        return [];
    }
}

export function StateSelectFilterHelper_dropDownValue(stateSelectFilters, searchFilterId) {
    const selectedFilter = StateSelectFilterHelper_tryGet(stateSelectFilters, searchFilterId);
    if (selectedFilter != null) {
        const matchValue = selectedFilter.State;
        switch (matchValue.tag) {
            case 1: {
                SystemError(`Unknown stateFilter [${searchFilterId}] when searching for TypeAheadSearc`);
                return void 0;
            }
            case 2:
                return matchValue.fields[0].SelectedItem;
            default: {
                SystemError(`Unknown stateFilter [${searchFilterId}] when searching for MultiSelectSearch`);
                return void 0;
            }
        }
    }
    else {
        SystemError(`Unknown stateFilter [${searchFilterId}]`);
        return void 0;
    }
}

