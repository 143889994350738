import * as signalr_1 from "@microsoft/signalr";
import { singleton } from "./fable_modules/fable-library.4.10.0/List.js";

export const signalr = signalr_1;

export function withUrl(url, signalR) {
    return signalR.withUrl(url);
}

export function configureLogging(logLevel, signalR) {
    return signalR.configureLogging(logLevel);
}

export function build(signalR) {
    return signalR.build();
}

export function register(callbackName, callback, connection) {
    return [connection, singleton((dispatch) => {
        connection.on(callbackName, (message) => ((msg) => {
            dispatch(callback(msg));
        })(message));;
        connection.start().catch(console.error);;
    })];
}

