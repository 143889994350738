import { Union, Record } from "./fable_modules/fable-library.4.10.0/Types.js";
import { union_type, enum_type, int32_type, array_type, bool_type, record_type, obj_type, string_type } from "./fable_modules/fable-library.4.10.0/Reflection.js";
import { FootPrintClaimResponse, UserResponse, PagedDataResponse$1, RoleIdResponse } from "../FootPrint.Portal.DTOs/DTOs.fs.js";
import { empty } from "./fable_modules/fable-library.4.10.0/List.js";

export class DataSourceItem extends Record {
    constructor(Id, DisplayValue, Item) {
        super();
        this.Id = Id;
        this.DisplayValue = DisplayValue;
        this.Item = Item;
    }
}

export function DataSourceItem_$reflection() {
    return record_type("FootPrint.Portal.UI.Models.DataSourceItem", [], DataSourceItem, () => [["Id", string_type], ["DisplayValue", string_type], ["Item", obj_type]]);
}

export class DataSource extends Record {
    constructor(SearchDataId, IsPaginated, Data) {
        super();
        this.SearchDataId = SearchDataId;
        this.IsPaginated = IsPaginated;
        this.Data = Data;
    }
}

export function DataSource_$reflection() {
    return record_type("FootPrint.Portal.UI.Models.DataSource", [], DataSource, () => [["SearchDataId", string_type], ["IsPaginated", bool_type], ["Data", array_type(DataSourceItem_$reflection())]]);
}

export class ExpandableItems$1 extends Record {
    constructor(IsExpanded, Items) {
        super();
        this.IsExpanded = IsExpanded;
        this.Items = Items;
    }
}

export function ExpandableItems$1_$reflection(gen0) {
    return record_type("FootPrint.Portal.UI.Models.ExpandableItems`1", [gen0], ExpandableItems$1, () => [["IsExpanded", bool_type], ["Items", array_type(gen0)]]);
}

export const Default_roleIdResponse = new RoleIdResponse(0);

export function Default_pagedData() {
    return new PagedDataResponse$1(0, 0, 0, 0, new Array(0));
}

export const Default_user = new UserResponse("", "", "", 7, empty(), empty(), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0), new Array(0));

export const Default_footPrintClaimResponse = new FootPrintClaimResponse(empty(), empty());

export class LayoutElement extends Record {
    constructor(Claim, Url, DisplayText) {
        super();
        this.Claim = (Claim | 0);
        this.Url = Url;
        this.DisplayText = DisplayText;
    }
}

export function LayoutElement_$reflection() {
    return record_type("FootPrint.Portal.UI.Models.LayoutElement", [], LayoutElement, () => [["Claim", enum_type("FootPrint.Portal.CustomTypes.FootPrintClaim", int32_type, [["UploadASNS", 1], ["UploadReservations", 2], ["Upload940S", 3], ["ManageAccounts", 4], ["ManageMaterials", 5], ["ViewInboundDetailsQuery", 6], ["ViewInboundSummaryQuery", 7], ["ViewOnHandDetailsQuery", 8], ["ViewOutboundDetailsQuery", 9], ["ViewOutboundSummaryQuery", 10], ["ViewReservationsQuery", 11], ["ViewMaterialsQuery", 12], ["ViewShippingMatrixQuery", 13], ["EditRolesAndPermissions", 14], ["Unknown", 15]])], ["Url", string_type], ["DisplayText", string_type]]);
}

export class SearchFilterKey extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Account", "Project", "Material", "Warehouse", "ExternalASNNo", "WMSReceiptNo", "WaybillNo", "CartonID", "PalletID", "AWSSKU", "SupplierSKU", "ShipToODM", "ASNReference", "ReservationId", "WMSOrderNo", "ExternalOrderNo", "BuyerPO", "TrackingId", "ReservationFlag", "SerialFlag", "DateRange", "DateExpected", "DateReceived", "LookupCode", "Name", "UserSelect", "RoleSelect", "MaterialLookupCode", "MaterialName", "AWSPartNo"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Project" : ((this$.tag === 2) ? "Material" : ((this$.tag === 3) ? "Warehouse" : ((this$.tag === 4) ? "External ASN #" : ((this$.tag === 5) ? "WMS Receipt #" : ((this$.tag === 6) ? "Waybill #" : ((this$.tag === 7) ? "Carton ID" : ((this$.tag === 8) ? "Pallet ID" : ((this$.tag === 9) ? "AWS SKU" : ((this$.tag === 10) ? "Supplier SKU" : ((this$.tag === 11) ? "Ship to ODM" : ((this$.tag === 12) ? "ASN Reference" : ((this$.tag === 13) ? "Reservation Id" : ((this$.tag === 14) ? "WMS Order #" : ((this$.tag === 15) ? "External Order #" : ((this$.tag === 16) ? "Buyer PO" : ((this$.tag === 17) ? "Tracking ID/Pro #" : ((this$.tag === 18) ? "Do Not Use Reservation" : ((this$.tag === 19) ? "Requires Child Serials" : ((this$.tag === 20) ? "Date Range" : ((this$.tag === 21) ? "Date Expected" : ((this$.tag === 22) ? "Date Received" : ((this$.tag === 23) ? "Lookup Code" : ((this$.tag === 24) ? "Name" : ((this$.tag === 25) ? "Select User" : ((this$.tag === 26) ? "Select Role" : ((this$.tag === 27) ? "Material Lookup Code" : ((this$.tag === 28) ? "Material Name" : ((this$.tag === 29) ? "AWS Part Number" : "Account"))))))))))))))))))))))))))));
    }
}

export function SearchFilterKey_$reflection() {
    return union_type("FootPrint.Portal.UI.Models.SearchFilterKey", [], SearchFilterKey, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class MultiSearchParams extends Record {
    constructor(Name, SearchCriteria) {
        super();
        this.Name = Name;
        this.SearchCriteria = SearchCriteria;
    }
}

export function MultiSearchParams_$reflection() {
    return record_type("FootPrint.Portal.UI.Models.MultiSearchParams", [], MultiSearchParams, () => [["Name", SearchFilterKey_$reflection()], ["SearchCriteria", string_type]]);
}

