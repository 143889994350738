import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, unit_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { ApiMessageResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_success, Toast_texts, Toast_title, Toast_error } from "../Components/SweetAlert.fs.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { changePassword as changePassword_1 } from "../API/AuthenticationAPI.fs.js";
import { createElement } from "react";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";

export class State extends Record {
    constructor(UserName, NewPassword, ResetPasswordCode, ChangePasswordDeferred) {
        super();
        this.UserName = UserName;
        this.NewPassword = NewPassword;
        this.ResetPasswordCode = ResetPasswordCode;
        this.ChangePasswordDeferred = ChangePasswordDeferred;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.ChangePassword.State", [], State, () => [["UserName", string_type], ["NewPassword", string_type], ["ResetPasswordCode", string_type], ["ChangePasswordDeferred", Deferred$1_$reflection(unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUserName", "SetPassword", "SetResetPasswordCode", "ChangePasswordAsync"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.ChangePassword.Msg", [], Msg, () => [[["userName", string_type]], [["password", string_type]], [["resetPasswordCode", string_type]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]]]);
}

export function init() {
    return [new State("", "", "", new Deferred$1(0, [])), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.UserName, msg.fields[0], state.ResetPasswordCode, state.ChangePasswordDeferred), Cmd_none()];
        case 2:
            return [new State(state.UserName, state.NewPassword, msg.fields[0], state.ChangePasswordDeferred), Cmd_none()];
        case 3:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Toast_error(Toast_title("Change Password", Toast_texts(ofArray(input_1.fields[0].Messages)))) : Toast_success(Toast_title("Change Password", Toast_text("Your password has been changed!"))));
                    return [new State((msg.fields[0].fields[0].tag === 1) ? state.UserName : "", (msg.fields[0].fields[0].tag === 1) ? state.NewPassword : "", (msg.fields[0].fields[0].tag === 1) ? state.ResetPasswordCode : "", new Deferred$1(2, [void 0])), command_1];
                }
                default:
                    if ((isNullOrWhiteSpace(state.UserName) ? true : isNullOrWhiteSpace(state.NewPassword)) ? true : isNullOrWhiteSpace(state.ResetPasswordCode)) {
                        return [state, Cmd_none()];
                    }
                    else if (equals(state.ChangePasswordDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.UserName, state.NewPassword, state.ResetPasswordCode, new Deferred$1(1, [])), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, () => changePassword_1(state.UserName, state.NewPassword, state.ResetPasswordCode), void 0, (arg) => (new Msg(3, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        default:
            return [new State(msg.fields[0], state.NewPassword, state.ResetPasswordCode, state.ChangePasswordDeferred), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_3, elems_2, elems_1, elems, value_24, value_37, value_50;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns", "is-mobile", "is-centered", "is-vcentered"])], ["style", {
        height: 80 + "vh",
    }], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_1 = [createElement("form", createObj(ofArray([["className", join(" ", ["is-flex", "is-flex-direction-column"])], ["onKeyPress", (keyPress) => {
        if (keyPress.key === "Enter") {
            dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
        }
    }], (elems = [createElement("img", {
        className: join(" ", ["image", "p-6"]),
        alt: "Crane Logo",
        src: "https://craneww-assets.azureedge.net/assets/crane-logo.svg",
    }), createElement("label", {
        children: "User Name",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["disabled", equals(state.ChangePasswordDeferred, new Deferred$1(1, []))], ["type", "text"], ["autoComplete", "off"], ["placeholder", "Enter user name..."], (value_24 = state.UserName, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_24)) {
            e.value = value_24;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))), createElement("br", {}), createElement("label", {
        children: "Reset Password Code",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["disabled", equals(state.ChangePasswordDeferred, new Deferred$1(1, []))], ["type", "text"], ["autoComplete", "off"], ["placeholder", "Enter reset password code..."], (value_37 = state.ResetPasswordCode, ["ref", (e_1) => {
        if (!(e_1 == null) && !equals(e_1.value, value_37)) {
            e_1.value = value_37;
        }
    }]), ["onChange", (ev_1) => {
        dispatch(new Msg(2, [ev_1.target.value]));
    }]]))), createElement("br", {}), createElement("label", {
        children: "New Password",
    }), createElement("input", createObj(ofArray([["className", join(" ", ["input", "is-small", "p-2", "mt-1"])], ["disabled", equals(state.ChangePasswordDeferred, new Deferred$1(1, []))], ["type", "password"], ["autoComplete", "off"], ["placeholder", "Enter new password..."], (value_50 = state.NewPassword, ["ref", (e_2) => {
        if (!(e_2 == null) && !equals(e_2.value, value_50)) {
            e_2.value = value_50;
        }
    }]), ["onChange", (ev_2) => {
        dispatch(new Msg(1, [ev_2.target.value]));
    }]]))), createElement("button", {
        className: join(" ", toList(delay(() => append(singleton("button"), delay(() => append(singleton("is-primary"), delay(() => append(singleton("is-small"), delay(() => append(singleton("my-4"), delay(() => append(singleton("px-4"), delay(() => append(singleton("py-2"), delay(() => (equals(state.ChangePasswordDeferred, new Deferred$1(1, [])) ? singleton("is-loading") : empty())))))))))))))))),
        type: "button",
        children: "Change Password",
        disabled: (isNullOrWhiteSpace(state.UserName) ? true : isNullOrWhiteSpace(state.NewPassword)) ? true : equals(state.ChangePasswordDeferred, new Deferred$1(1, [])),
        onClick: (_arg) => {
            dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
        },
    }), createElement("a", {
        className: join(" ", ["mt-4", "has-text-centered", "is-link"]),
        href: RouterModule_encodeParts(singleton_1("/"), 1),
        children: "Sign in",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

