import { toBase64String, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Convert_fromJson, Convert_serialize } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { get_UTF8 } from "../fable_modules/fable-library.4.10.0/Encoding.js";
import { ChangePasswordRequest_$reflection, ChangePasswordRequest, ResetPasswordRequest_$reflection, ResetPasswordRequest, IdTokenResponse_$reflection, ApiMessageResponseModule_mapMessages, LoginRequest_$reflection, LoginRequest } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { Headers_contentType, Http_header, Http_request, Http_method, Http_content, Http_send } from "../fable_modules/Fable.SimpleHttp.3.6.0/Http.fs.js";
import { HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.6.0/Types.fs.js";
import { FSharpResult$2, Result_Map } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SimpleJson_parse, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

let idToken = "";

export function getIdToken() {
    return idToken;
}

export function isAuthenticated() {
    return !isNullOrWhiteSpace(idToken);
}

export function login(userName, password) {
    return singleton.Delay(() => {
        let objectArg, req_2;
        const json = Convert_serialize(new LoginRequest(userName, toBase64String((objectArg = get_UTF8(), objectArg.getBytes(password)))), createTypeInfo(LoginRequest_$reflection()));
        return singleton.Bind(Http_send((req_2 = Http_content(new BodyContent(1, [json]), Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/login`))), Http_header(Headers_contentType("application/json"), req_2))), (_arg) => {
            let response_2, toGeneralError, input_4, input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_Map((x_1) => {
                idToken = x_1.IdToken;
            }, (response_2 = response, (toGeneralError = ((error) => {
                console.error(some("API error"), error);
                return ApiMessageResponseModule_mapMessages(["Something went wrong. Please contact the system administrator."]);
            }), isNullOrWhiteSpace(response_2.responseText) ? (new FSharpResult$2(1, [toGeneralError("The response has no content. Failed to parse the DTO.")])) : ((input_4 = ((input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parse(response_2.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(IdTokenResponse_$reflection()))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())), (input_4.tag === 1) ? (new FSharpResult$2(1, [toGeneralError(input_4.fields[0])])) : (new FSharpResult$2(0, [input_4.fields[0]]))))))) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function logout() {
    idToken = "";
}

export function resetPassword(userName, emailAddress) {
    return singleton.Delay(() => {
        let req_2;
        const json = Convert_serialize(new ResetPasswordRequest(userName, emailAddress), createTypeInfo(ResetPasswordRequest_$reflection()));
        return singleton.Bind(Http_send((req_2 = Http_content(new BodyContent(1, [json]), Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/resetPassword`))), Http_header(Headers_contentType("application/json"), req_2))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 204) {
                idToken = "";
                result = (new FSharpResult$2(0, [void 0]));
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

export function changePassword(userName, newPassword, resetPasswordCode) {
    return singleton.Delay(() => {
        let objectArg, req_2;
        const json = Convert_serialize(new ChangePasswordRequest(userName, toBase64String((objectArg = get_UTF8(), objectArg.getBytes(newPassword))), resetPasswordCode), createTypeInfo(ChangePasswordRequest_$reflection()));
        return singleton.Bind(Http_send((req_2 = Http_content(new BodyContent(1, [json]), Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/users/changePassword`))), Http_header(Headers_contentType("application/json"), req_2))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 204) {
                idToken = "";
                result = (new FSharpResult$2(0, [void 0]));
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

