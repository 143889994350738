import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { PagedDataResponse$1, AdvancedShippingNoticeResponse, OrderStatusChangedResponse_$reflection, ApiMessageResponse_$reflection, AdvancedShippingNoticeSubDetailResponse_$reflection, AdvancedShippingNoticeDetailResponse_$reflection, PagedDataResponse$1_$reflection, AdvancedShippingNoticeResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Deferred_map, Cmd_resetForm, AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { string_type, union_type, record_type, unit_type, int32_type, option_type, class_type, array_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { Default_pagedData, ExpandableItems$1, DataSourceItem_$reflection, ExpandableItems$1_$reflection } from "../Models.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { toString } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { collect, empty as empty_1, map as map_1, singleton as singleton_1, append, delay, toList, exists } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { defaultArg, toArray } from "../fable_modules/fable-library.4.10.0/Option.js";
import { withUrl, signalr as signalr_1, configureLogging, build, register } from "../SignalR.fs.js";
import { containsKey, remove, add, tryFind as tryFind_1, empty } from "../fable_modules/fable-library.4.10.0/Map.js";
import { createObj, equals, comparePrimitives } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { tryFind, ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { orderStatuses } from "../API/DataSourceAPI.fs.js";
import { Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { downloadTemplate, getSubDetails, getDetails, search as search_1 } from "../API/AdvancedShippingNoticeAPI.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { upload as upload_1 } from "../API/OrderAPI.fs.js";
import { FileType } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";
import { map } from "../fable_modules/fable-library.4.10.0/Array.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { formatDecimal, formatInt, formatStatusDescriptionAsClass, formatOrderStatusAsClass, formatDateTime } from "../Domain/Formatter.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.7.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(PagedDataDeferred, DetailsDeferred, SubDetailsDeferred, Details, SubDetails, CurrentExpandedItemId, Page, SelectedOrderStatus, UploadFile, DownloadFileDeferred, UploadFileDeferred, SignalRHub) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.DetailsDeferred = DetailsDeferred;
        this.SubDetailsDeferred = SubDetailsDeferred;
        this.Details = Details;
        this.SubDetails = SubDetails;
        this.CurrentExpandedItemId = CurrentExpandedItemId;
        this.Page = (Page | 0);
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.UploadFile = UploadFile;
        this.DownloadFileDeferred = DownloadFileDeferred;
        this.UploadFileDeferred = UploadFileDeferred;
        this.SignalRHub = SignalRHub;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.AdvancedShippingNoticeUpload.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(AdvancedShippingNoticeResponse_$reflection()))], ["DetailsDeferred", Deferred$1_$reflection(array_type(AdvancedShippingNoticeDetailResponse_$reflection()))], ["SubDetailsDeferred", Deferred$1_$reflection(array_type(AdvancedShippingNoticeSubDetailResponse_$reflection()))], ["Details", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), ExpandableItems$1_$reflection(AdvancedShippingNoticeDetailResponse_$reflection())])], ["SubDetails", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), ExpandableItems$1_$reflection(AdvancedShippingNoticeSubDetailResponse_$reflection())])], ["CurrentExpandedItemId", option_type(class_type("System.Guid"))], ["Page", int32_type], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["UploadFile", option_type(class_type("Browser.Types.File", void 0))], ["DownloadFileDeferred", Deferred$1_$reflection(unit_type)], ["UploadFileDeferred", Deferred$1_$reflection(unit_type)], ["SignalRHub", class_type("SignalR.IHubConnection")]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SearchAsync", "GetDetailsAsync", "GetSubDetailsAsync", "DownloadFileAsync", "UploadFileAsync", "SetNextPage", "SetPreviousPage", "SetOrderStatus", "SetUploadFile", "ToggleDetails", "ToggleSubDetails", "OrderStatusChanged"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.AdvancedShippingNoticeUpload.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(AdvancedShippingNoticeResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(AdvancedShippingNoticeResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["id", class_type("System.Guid")], ["Item2", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AdvancedShippingNoticeDetailResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(AdvancedShippingNoticeDetailResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["detailsId", class_type("System.Guid")], ["Item2", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(AdvancedShippingNoticeSubDetailResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(AdvancedShippingNoticeSubDetailResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [], [], [["orderStatus", string_type]], [["Item", class_type("Browser.Types.File", void 0)]], [["id", class_type("System.Guid")]], [["detailsId", class_type("System.Guid")]], [["Item", OrderStatusChangedResponse_$reflection()]]]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function isCurrentExpandedItem(state, itemId) {
    let input_1;
    const formattedItemId = toString(itemId, "N");
    const element = document.getElementById(formattedItemId);
    if (element == null) {
        return false;
    }
    else {
        return exists((id_1) => (id_1 === element.id), toArray((input_1 = state.CurrentExpandedItemId, (input_1 == null) ? void 0 : toString(input_1, "N"))));
    }
}

export function init() {
    let signalR;
    const patternInput = register("OrderStatusChanged", (Item) => (new Msg(11, [Item])), build(configureLogging(1, (signalR = (new signalr_1.HubConnectionBuilder()), withUrl(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api`, signalR)))));
    return [new State(new Deferred$1(0, []), new Deferred$1(0, []), new Deferred$1(0, []), empty({
        Compare: comparePrimitives,
    }), empty({
        Compare: comparePrimitives,
    }), void 0, 1, void 0, void 0, new Deferred$1(0, []), new Deferred$1(0, []), patternInput[0]), Cmd_batch(ofArray([patternInput[1], singleton((dispatch) => {
        dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
    })]))];
}

export function update(msg, state) {
    let input_1, value_1, input_3, input_5, value_3, input_7, input_15, res_1, res_3, input_21, input_25;
    switch (msg.tag) {
        case 5:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page + 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 6:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page - 1, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 8:
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, msg.fields[0], state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_2) => {
                dispatch_2(new Msg(4, [new AsyncMsg$1(0, [])]));
            })];
        case 7:
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, 1, tryFind((x_1) => (x_1.Id === msg.fields[0]), orderStatuses), state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 9: {
            const toggle = defaultArg((input_1 = tryFind_1(msg.fields[0], state.Details), (input_1 == null) ? void 0 : input_1.IsExpanded), false);
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, add(msg.fields[0], (value_1 = (new ExpandableItems$1(false, new Array(0))), defaultArg((input_3 = tryFind_1(msg.fields[0], state.Details), (input_3 == null) ? void 0 : (new ExpandableItems$1(!toggle, input_3.Items))), value_1)), remove(msg.fields[0], state.Details)), state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
        }
        case 10: {
            const toggle_1 = defaultArg((input_5 = tryFind_1(msg.fields[0], state.SubDetails), (input_5 == null) ? void 0 : input_5.IsExpanded), false);
            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, add(msg.fields[0], (value_3 = (new ExpandableItems$1(false, new Array(0))), defaultArg((input_7 = tryFind_1(msg.fields[0], state.SubDetails), (input_7 == null) ? void 0 : (new ExpandableItems$1(!toggle_1, input_7.Items))), value_3)), remove(msg.fields[0], state.SubDetails)), state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
        }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_13 = msg.fields[0].fields[0];
                    command_1 = ((input_13.tag === 1) ? Alert_error(Alert_title("Search Advanced Shipping Notices", Alert_texts(ofArray(input_13.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_15 = msg.fields[0].fields[0], (input_15.tag === 1) ? Default_pagedData() : input_15.fields[0])]), state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        let orderStatusId;
                        let input_11;
                        const input_9 = state.SelectedOrderStatus;
                        input_11 = ((input_9 == null) ? void 0 : input_9.Id);
                        orderStatusId = ((input_11 == null) ? void 0 : Int32_fromString(input_11));
                        return [new State(new Deferred$1(1, []), state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_3) => {
                            Cmd_OfAsync_start(x_3);
                        }, () => search_1(orderStatusId, state.Page), void 0, (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[1].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_3;
                    const input_17 = msg.fields[1].fields[0];
                    command_3 = ((input_17.tag === 1) ? Alert_error(Alert_title("Advanced Shipping Notice Details", Alert_texts(ofArray(input_17.fields[0].Messages)))) : singleton((dispatch_5) => {
                        dispatch_5(new Msg(9, [msg.fields[0]]));
                    }));
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [(res_1 = msg.fields[1].fields[0], (res_1.tag === 1) ? (new Array(0)) : res_1.fields[0])]), state.SubDetailsDeferred, (msg.fields[1].fields[0].tag === 0) ? add(msg.fields[0], new ExpandableItems$1(false, msg.fields[1].fields[0].fields[0]), remove(msg.fields[0], state.Details)) : state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_3];
                }
                default:
                    if (equals(state.DetailsDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (containsKey(msg.fields[0], state.Details)) {
                        return [state, singleton((dispatch_4) => {
                            dispatch_4(new Msg(9, [msg.fields[0]]));
                        })];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.SubDetailsDeferred, state.Details, state.SubDetails, msg.fields[0], state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getDetails, msg.fields[0], (result_1) => (new Msg(1, [msg.fields[0], new AsyncMsg$1(2, [result_1])])))];
                    }
            }
        case 2:
            switch (msg.fields[1].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_5;
                    const input_19 = msg.fields[1].fields[0];
                    command_5 = ((input_19.tag === 1) ? Alert_error(Alert_title("Advanced Shipping Notice Sub Details", Alert_texts(ofArray(input_19.fields[0].Messages)))) : singleton((dispatch_7) => {
                        dispatch_7(new Msg(10, [msg.fields[0]]));
                    }));
                    return [new State(state.PagedDataDeferred, state.DetailsDeferred, new Deferred$1(2, [(res_3 = msg.fields[1].fields[0], (res_3.tag === 1) ? (new Array(0)) : res_3.fields[0])]), state.Details, (msg.fields[1].fields[0].tag === 0) ? add(msg.fields[0], new ExpandableItems$1(false, msg.fields[1].fields[0].fields[0]), remove(msg.fields[0], state.SubDetails)) : state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), command_5];
                }
                default:
                    if (equals(state.SubDetailsDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (containsKey(msg.fields[0], state.SubDetails)) {
                        return [state, singleton((dispatch_6) => {
                            dispatch_6(new Msg(10, [msg.fields[0]]));
                        })];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.DetailsDeferred, new Deferred$1(1, []), state.Details, state.SubDetails, msg.fields[0], state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, getSubDetails, msg.fields[0], (result_3) => (new Msg(2, [msg.fields[0], new AsyncMsg$1(2, [result_3])])))];
                    }
            }
        case 3:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(2, [void 0]), state.UploadFileDeferred, state.SignalRHub), (input_21 = msg.fields[0].fields[0], (input_21.tag === 1) ? Alert_error(Alert_title("Download Template", Alert_texts(ofArray(input_21.fields[0].Messages)))) : Cmd_none())];
                default:
                    if (equals(state.DownloadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, new Deferred$1(1, []), state.UploadFileDeferred, state.SignalRHub), Cmd_OfAsyncWith_perform((x_11) => {
                            Cmd_OfAsync_start(x_11);
                        }, downloadTemplate, void 0, (arg_4) => (new Msg(3, [new AsyncMsg$1(2, [arg_4])])))];
                    }
            }
        case 4:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    const uploaded = !(msg.fields[0].fields[0].tag === 1);
                    return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, uploaded ? 1 : state.Page, uploaded ? void 0 : state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(2, [void 0]), state.SignalRHub), Cmd_batch(ofArray([(input_25 = msg.fields[0].fields[0], (input_25.tag === 1) ? Alert_error(Alert_title("Upload Advanced Shipping Notices", Alert_texts(ofArray(input_25.fields[0].Messages)))) : singleton((dispatch_8) => {
                        dispatch_8(new Msg(0, [new AsyncMsg$1(0, [])]));
                    })), Cmd_resetForm("upload-form")]))];
                }
                default:
                    if (equals(state.UploadFileDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else {
                        const matchValue = state.UploadFile;
                        if (matchValue != null) {
                            const file_1 = matchValue;
                            return [new State(state.PagedDataDeferred, state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, new Deferred$1(1, []), state.SignalRHub), Cmd_OfAsyncWith_perform((computation_2) => {
                                startImmediate(computation_2);
                            }, () => upload_1(new FileType(1, []), file_1), void 0, (arg_6) => (new Msg(4, [new AsyncMsg$1(2, [arg_6])])))];
                        }
                        else {
                            return [state, Cmd_none()];
                        }
                    }
            }
        default:
            if (msg.fields[0].OrderType === "AdvancedShippingNotice") {
                return [new State(Deferred_map((pagedData) => (new PagedDataResponse$1(pagedData.Page, pagedData.PageSize, pagedData.TotalCount, pagedData.NumberOfPages, map((item) => {
                    if (item.Id === msg.fields[0].OrderId) {
                        return new AdvancedShippingNoticeResponse(item.Id, item.WarehouseId, item.ReceiptType, item.AsnReferenceNumber, item.Mode, item.SupplierCode, item.ShipDate, item.ShipFrom, item.DestinationCity, item.DestinationCountry, item.TrackingNumber, item.Forwarder, item.ExpressFlag, item.Eta, item.SupplierName, item.RateTerm, item.PaidFlag, item.Eadc, item.BondedFlag, item.CreatedBy, item.CreatedOn, item.UploadedOnFormatted, item.OrderStatusId, msg.fields[0].OrderStatus, msg.fields[0].OrderStatusDescription);
                    }
                    else {
                        return item;
                    }
                }, pagedData.Data))), state.PagedDataDeferred), state.DetailsDeferred, state.SubDetailsDeferred, state.Details, state.SubDetails, state.CurrentExpandedItemId, state.Page, state.SelectedOrderStatus, state.UploadFile, state.DownloadFileDeferred, state.UploadFileDeferred, state.SignalRHub), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function render(state, dispatch) {
    let elems_43, elems_20, elems_1, elems, matchValue, pagedData, elems_19, elems_4, elems_3, value_24, input_1, elems_2, elems_10, elems_9, elems_6, elems_5, elems_8, elems_7, elems_13, elems_12, elems_11, elems_18, elems_17, elems_16, elems_15, elems_14, input_3, x_1, elems_42;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "is-fluid"])], (elems_43 = [createElement("form", createObj(ofArray([["id", "upload-form"], ["className", "box"], (elems_20 = [createElement("h1", {
        className: join(" ", ["has-text-weight-semibold", "has-text-link"]),
        children: "Advanced Shipping Notices",
    }), createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-self-center"])], (elems = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "columns"], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_3 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small"])], ["disabled", equals(state.PagedDataDeferred, new Deferred$1(1, []))], (value_24 = defaultArg((input_1 = state.SelectedOrderStatus, (input_1 == null) ? void 0 : input_1.Id), ""), ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_24)) {
            e.value = value_24;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(7, [ev.target.value]));
    }], (elems_2 = toList(delay(() => append(singleton_1(createElement("option", {
        children: "-- Order Status --",
        value: "",
        selected: true,
    })), delay(() => map_1((item) => createElement("option", {
        children: item.DisplayValue,
        value: item.Id,
    }), orderStatuses))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["buttons", "is-right"])], (elems_9 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_6 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_5 = [createElement("i", {
        children: ["navigate_before"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Previous",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]), ["onClick", (_arg) => {
        dispatch(new Msg(6, []));
    }]]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : equals(state.PagedDataDeferred, new Deferred$1(1, []))], (elems_8 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_7 = [createElement("i", {
        children: ["navigate_next"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("span", {
        className: "is-sr-only",
        children: "Next",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]), ["onClick", (_arg_1) => {
        dispatch(new Msg(5, []));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_13 = [createElement("button", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("button"), delay(() => append(singleton_1("is-small"), delay(() => append(singleton_1("is-fullwidth"), delay(() => (equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1()))))))))))], ["type", "button"], ["disabled", equals(state.DownloadFileDeferred, new Deferred$1(1, []))], (elems_12 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_11 = [createElement("i", {
        children: ["file_download"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("span", {
        children: [equals(state.DownloadFileDeferred, new Deferred$1(1, [])) ? "Downloading..." : "Download Template"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))]), ["onClick", (_arg_2) => {
        dispatch(new Msg(3, [new AsyncMsg$1(0, [])]));
    }]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("div", createObj(ofArray([["className", "column"], (elems_18 = [createElement("div", createObj(ofArray([["className", join(" ", ["file", "has-name", "is-small", "is-fullwidth"])], (elems_17 = [createElement("label", createObj(ofArray([["className", "file-label"], (elems_16 = [createElement("input", {
        className: join(" ", toList(delay(() => append(singleton_1("file-input"), delay(() => (equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? singleton_1("is-loading") : empty_1())))))),
        type: "file",
        name: "OrderFileUpload",
        disabled: equals(state.UploadFileDeferred, new Deferred$1(1, [])),
        onChange: (ev_1) => {
            const files = ev_1.target.files;
            if (!(files == null) && (files.length > 0)) {
                dispatch(new Msg(8, [files.item(0)]));
            }
        },
    }), createElement("span", createObj(ofArray([["className", "file-cta"], (elems_15 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "mr-3"])], (elems_14 = [createElement("i", {
        children: ["file_upload"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("span", {
        className: "file-label",
        children: equals(state.UploadFileDeferred, new Deferred$1(1, [])) ? "Uploading..." : "Upload",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("span", {
        className: "file-name",
        children: defaultArg((input_3 = state.UploadFile, (input_3 == null) ? void 0 : ((x_1 = input_3, x_1.name))), ""),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_42 = toList(delay(() => {
        let elems_41, elems_40, elems_22, elems_21, elems_39;
        const matchValue_1 = state.PagedDataDeferred;
        switch (matchValue_1.tag) {
            case 1:
                return singleton_1(render_1());
            case 2: {
                const pagedData_1 = matchValue_1.fields[0];
                if (pagedData_1.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "Nothing to see here...",
                    }));
                }
                else {
                    const tdClasses = ofArray(["px-3", "py-2"]);
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_41 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_40 = [createElement("thead", createObj(singleton((elems_22 = [createElement("tr", createObj(singleton((elems_21 = [tableHeader(""), tableHeader("Warehouse ID"), tableHeader("Receipt Type"), tableHeader("ASN Reference #"), tableHeader("Mode"), tableHeader("Supplier Code"), tableHeader("Supplier Name"), tableHeader("Ship Date"), tableHeader("Ship From"), tableHeader("Destination City"), tableHeader("Destination County"), tableHeader("Tracking #"), tableHeader("Forwarder"), tableHeader("Express Flag"), tableHeader("ETA"), tableHeader("Rate Term"), tableHeader("Paid Flag"), tableHeader("EADC"), tableHeader("Bonded Flag"), tableHeader("Uploaded By"), tableHeader("Uploaded On"), tableHeader("Order Status"), tableHeader("Order Status Description")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])))), createElement("tbody", createObj(singleton((elems_39 = toList(delay(() => collect((item_1) => {
                        let elems_27, elems_24, elems_23, elems_25, elems_26;
                        return append(singleton_1(createElement("tr", createObj(ofArray([["id", toString(item_1.Id, "N")], (elems_27 = [createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_24 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("material-icons"), delay(() => append(singleton_1("mr-2"), delay(() => append(singleton_1("is-clickable"), delay(() => ((equals(state.DetailsDeferred, new Deferred$1(1, [])) && isCurrentExpandedItem(state, item_1.Id)) ? singleton_1("is-borderless button is-small is-loading") : empty_1()))))))))))], ["onClick", (_arg_3) => {
                            dispatch(new Msg(1, [item_1.Id, new AsyncMsg$1(0, [])]));
                        }], (elems_23 = toList(delay(() => {
                            let matchValue_2;
                            const input_5 = tryFind_1(item_1.Id, state.Details);
                            matchValue_2 = ((input_5 == null) ? void 0 : input_5.IsExpanded);
                            return (matchValue_2 != null) ? (matchValue_2 ? singleton_1(createElement("i", {
                                children: ["expand_less"],
                            })) : singleton_1(createElement("i", {
                                children: ["expand_more"],
                            }))) : singleton_1(createElement("i", {
                                children: ["expand_more"],
                            }));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.WarehouseId,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ReceiptType,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.AsnReferenceNumber,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Mode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SupplierCode,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.SupplierName,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: formatDateTime(item_1.ShipDate),
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ShipFrom,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.DestinationCity,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.DestinationCountry,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.TrackingNumber,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.Forwarder,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.ExpressFlag,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: formatDateTime(item_1.Eta),
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.RateTerm,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: equals(item_1.PaidFlag, true) ? "Y" : "N",
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: formatDateTime(item_1.Eadc),
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: equals(item_1.BondedFlag, true) ? "Y" : "N",
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.CreatedBy,
                        }), createElement("td", {
                            className: join(" ", tdClasses),
                            children: item_1.UploadedOnFormatted,
                        }), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_25 = [createElement("span", {
                            className: join(" ", ["tag", "is-flex", "is-rounded", formatOrderStatusAsClass(item_1.OrderStatusName)]),
                            children: item_1.OrderStatusName,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_26 = [createElement("span", {
                            className: join(" ", [formatStatusDescriptionAsClass(item_1.OrderStatusName, item_1.StatusDescription)]),
                            children: item_1.StatusDescription,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))), delay(() => {
                            let elems_38, elems_37, elems_36, elems_35, children_2, children, children_12;
                            const matchValue_3 = tryFind_1(item_1.Id, state.Details);
                            if (matchValue_3 != null) {
                                const details = matchValue_3;
                                return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                    let input_7;
                                    return !defaultArg((input_7 = tryFind_1(item_1.Id, state.Details), (input_7 == null) ? void 0 : input_7.IsExpanded), false) ? singleton_1("is-hidden") : empty_1();
                                })))], (elems_38 = [createElement("td", createObj(ofArray([["className", join(" ", ["px-6", "py-4"])], ["colSpan", 100], (elems_37 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "px-6"])], (elems_36 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_35 = [(children_2 = singleton((children = ofArray([tableHeader(""), tableHeader("ASN Reference #"), tableHeader("ASN Reference Line #"), tableHeader("Carton ID"), tableHeader("Supplier Part #"), tableHeader("Qty Shipped"), tableHeader("Country of Origin"), tableHeader("Serial / Batch # Indicator"), tableHeader("AWS Part #"), tableHeader("Pallet ID"), tableHeader("Gross Weight"), tableHeader("Weight UOM (KG)"), tableHeader("Cube (CBM)"), tableHeader("# of Carton"), tableHeader("Net Weight"), tableHeader("Carton Dimension"), tableHeader("Currency"), tableHeader("Unit Price"), tableHeader("HS Code"), tableHeader("Country of Provenance"), tableHeader("Request Delivery Date"), tableHeader("Hot Part"), tableHeader("Purchase Order")]), createElement("tr", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                                }))), createElement("thead", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                })), (children_12 = toList(delay(() => collect((detail) => {
                                    let elems_30, elems_29, elems_28;
                                    return append(singleton_1(createElement("tr", createObj(ofArray([["id", toString(detail.Id, "N")], (elems_30 = [createElement("td", createObj(ofArray([["className", join(" ", tdClasses)], (elems_29 = [createElement("span", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("material-icons"), delay(() => append(singleton_1("mr-2"), delay(() => append(singleton_1("is-clickable"), delay(() => ((equals(state.SubDetailsDeferred, new Deferred$1(1, [])) && isCurrentExpandedItem(state, detail.Id)) ? singleton_1("is-borderless button is-small is-loading") : empty_1()))))))))))], ["onClick", (_arg_4) => {
                                        dispatch(new Msg(2, [detail.Id, new AsyncMsg$1(0, [])]));
                                    }], (elems_28 = toList(delay(() => {
                                        let matchValue_4;
                                        const input_9 = tryFind_1(detail.Id, state.SubDetails);
                                        matchValue_4 = ((input_9 == null) ? void 0 : input_9.IsExpanded);
                                        return (matchValue_4 != null) ? (matchValue_4 ? singleton_1(createElement("i", {
                                            children: ["expand_less"],
                                        })) : singleton_1(createElement("i", {
                                            children: ["expand_more"],
                                        }))) : singleton_1(createElement("i", {
                                            children: ["expand_more"],
                                        }));
                                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])]))), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AsnReferenceNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AsnLineNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.CartonId,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.SupplierPartNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: formatInt(detail.QtyShipped),
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.CountryOfOrigin,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.SerialIndicator,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.AwsPartNumber,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.PalletId,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: formatDecimal(detail.GrossWeight),
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.WeightUom,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: formatDecimal(detail.Cube),
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.NumberOfCarton,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: formatDecimal(detail.NetWeight),
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.CartonDimension,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.Currency,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.UnitPrice,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.HsCode,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.CountryOfProvenance,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: formatDateTime(detail.RequestDeliveryDate),
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.HotPart,
                                    }), createElement("td", {
                                        className: join(" ", tdClasses),
                                        children: detail.PurchaseOrder,
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))), delay(() => {
                                        let elems_34, elems_33, elems_32, elems_31, children_6, children_4, children_10;
                                        const matchValue_5 = tryFind_1(detail.Id, state.SubDetails);
                                        if (matchValue_5 != null) {
                                            const subDetails = matchValue_5;
                                            return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", toList(delay(() => {
                                                let input_11;
                                                return !defaultArg((input_11 = tryFind_1(detail.Id, state.SubDetails), (input_11 == null) ? void 0 : input_11.IsExpanded), false) ? singleton_1("is-hidden") : empty_1();
                                            })))], (elems_34 = [createElement("td", createObj(ofArray([["className", join(" ", ["px-6", "py-4"])], ["colSpan", 100], (elems_33 = [createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "px-6"])], (elems_32 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_31 = [(children_6 = singleton((children_4 = ofArray([tableHeader("ASN Reference #"), tableHeader("ASN Line #"), tableHeader("Carton ID"), tableHeader("Batch #"), tableHeader("Serial #"), tableHeader("Reference 1"), tableHeader("Reference 2"), tableHeader("Reference 3")]), createElement("tr", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                            }))), createElement("thead", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                                            })), (children_10 = toList(delay(() => map_1((subDetail) => {
                                                const children_8 = ofArray([createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.AsnReferenceNumber,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.AsnLineNumber,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.CartonId,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.BatchNumber,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.SerialNumber,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.Reference1,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.Reference2,
                                                }), createElement("td", {
                                                    className: join(" ", tdClasses),
                                                    children: subDetail.Reference3,
                                                })]);
                                                return createElement("tr", {
                                                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                                                });
                                            }, subDetails.Items))), createElement("tbody", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                                            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])]))));
                                        }
                                        else {
                                            return singleton_1(defaultOf());
                                        }
                                    }));
                                }, details.Items))), createElement("tbody", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])]))));
                            }
                            else {
                                return singleton_1(defaultOf());
                            }
                        }));
                    }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])]))));
                }
            }
            default:
                return singleton_1(defaultOf());
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])));
}

