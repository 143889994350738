import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";

export function render() {
    return createElement("p", {
        className: join(" ", ["has-text-centered"]),
        children: "The page you requested was not found",
    });
}

