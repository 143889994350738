import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { ofArray } from "../fable_modules/fable-library.4.10.0/List.js";

export function render() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container", "mx-auto", "p-6"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["loader-container"])], (elems = [createElement("div", {
        className: join(" ", ["loader"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

