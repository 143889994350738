import { toString, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ReportRequest, ApiMessageResponse_$reflection, PagedDataResponse$1_$reflection, OutboundDetailsReportResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { AsyncMsg$1, Deferred$1, Deferred_exists, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { union_type, record_type, class_type, enum_type, int32_type, option_type, bool_type, list_type, unit_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { StateSelectFilterHelper_renderSearch, StateSelectFilterHelper_extractDropDownFilter, StateSelectFilterHelper_extractTypeAheadFilter, StateSelectFilter, TypeStateHeld, StateSelectFilterHelper_tryGet, StateSelectFilterHelper_extractMultiSelectFilter, StateSelectFilterModule_addTypeAheadSearchFilter, StateSelectFilterModule_addMultiSearchFilter, TypeStateHeldModule_toDropDownSearch, TypeStateHeldModule_toTypeAheadSearchState, TypeStateHeldModule_toMultiSearchState, StateSelectFilterHelper_selectedItemsDisplayValues, StateSelectFilterHelper_selectedItemsIds, StateSelectFilter_$reflection } from "../Utilities/StateSelectFilterHelper.fs.js";
import { Default_pagedData, SearchFilterKey, DataSource_$reflection, DataSourceItem_$reflection } from "../Models.fs.js";
import { render as render_3, update as update_3, init as init_3, MultiDateRangeTypeModule_toInt, Msg_$reflection as Msg_$reflection_3, State_$reflection as State_$reflection_1 } from "../Components/MultiDateSearch.fs.js";
import { render as render_4, update as update_4, OrderTypeInboundOrOutbound, init as init_4, Msg_$reflection as Msg_$reflection_4, State_$reflection as State_$reflection_2 } from "../Components/OrderTypeStatus.fs.js";
import { render as render_2, update as update_5, init as init_5, Msg_$reflection as Msg_$reflection_6, State_$reflection as State_$reflection_3 } from "../Components/ExportReportDialog.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { render as render_1, update as update_1, extractName, init as init_1, SelectedItemsChangedData_$reflection, Msg_$reflection as Msg_$reflection_1 } from "../Components/MultiSelectSearch.fs.js";
import { render as render_5, update as update_2, extractName as extractName_1, init as init_2, Msg_$reflection as Msg_$reflection_2 } from "../Components/TypeAheadSearch.fs.js";
import { update as update_6, extractName as extractName_2, Msg_$reflection as Msg_$reflection_5 } from "../Components/SelectComponent.fs.js";
import { value as value_62 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { Int32_fromString } from "../../FootPrint.Portal.DTOs/PrimitiveTypes.fs.js";
import { createObj, equals, defaultOf } from "../fable_modules/fable-library.4.10.0/Util.js";
import { getAWSSKUs, getExternalOrderNos, getMaterials, getWarehouses, getAccounts, getProjects } from "../API/DataSourceAPI.fs.js";
import { isEmpty, map, singleton, ofArray } from "../fable_modules/fable-library.4.10.0/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { Toast_text, Toast_title, Toast_info, Alert_texts, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.1.0/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { exportOutboundDetails, getOutboundDetails } from "../API/ReportAPI.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { render as render_6 } from "../Components/Loader.fs.js";
import { formatDecimal, formatDateTime, formatInt } from "../Domain/Formatter.fs.js";

export class State extends Record {
    constructor(PagedDataDeferred, ExportDeferred, SelectFilters, ShowProjectSearch, SelectedOrderStatus, SelectedOrderType, DateFilter, DateRangeType, DateStart, DateEnd, OrderTypeStatus, ExportReportState, Page) {
        super();
        this.PagedDataDeferred = PagedDataDeferred;
        this.ExportDeferred = ExportDeferred;
        this.SelectFilters = SelectFilters;
        this.ShowProjectSearch = ShowProjectSearch;
        this.SelectedOrderStatus = SelectedOrderStatus;
        this.SelectedOrderType = SelectedOrderType;
        this.DateFilter = DateFilter;
        this.DateRangeType = (DateRangeType | 0);
        this.DateStart = DateStart;
        this.DateEnd = DateEnd;
        this.OrderTypeStatus = OrderTypeStatus;
        this.ExportReportState = ExportReportState;
        this.Page = (Page | 0);
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.OutboundDetailsQuery.State", [], State, () => [["PagedDataDeferred", Deferred$1_$reflection(PagedDataResponse$1_$reflection(OutboundDetailsReportResponse_$reflection()))], ["ExportDeferred", Deferred$1_$reflection(unit_type)], ["SelectFilters", list_type(StateSelectFilter_$reflection())], ["ShowProjectSearch", bool_type], ["SelectedOrderStatus", option_type(DataSourceItem_$reflection())], ["SelectedOrderType", option_type(DataSourceItem_$reflection())], ["DateFilter", State_$reflection_1()], ["DateRangeType", enum_type("FootPrint.Portal.UI.MultipleDateFilter.MultipleDateFilter.MultiDateRangeType", int32_type, [["Choose", 0], ["Created", 1], ["Processed", 2], ["Completed", 3], ["Cancelled", 4]])], ["DateStart", option_type(class_type("System.DateTime"))], ["DateEnd", option_type(class_type("System.DateTime"))], ["OrderTypeStatus", State_$reflection_2()], ["ExportReportState", State_$reflection_3()], ["Page", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["QueryAsync", "ExportAsync", "MultiSelectSearchMsg", "SetMultiSelectSearchItems", "TypeAheadSearchMsg", "SetTypeAheadSearchItems", "DateFilterMsg", "OrderTypeStatusMsg", "DropDownSearchMsg", "SetOrderStatus", "SetOrderType", "ExportReportMsg", "SetNextPage", "SetPreviousPage"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.OutboundDetailsQuery.Msg", [], Msg, () => [[["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagedDataResponse$1_$reflection(OutboundDetailsReportResponse_$reflection()), ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", PagedDataResponse$1_$reflection(OutboundDetailsReportResponse_$reflection())]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ApiMessageResponse_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ApiMessageResponse_$reflection()]]]))]], [["Item", Msg_$reflection_1()]], [["Item", SelectedItemsChangedData_$reflection()]], [["Item", Msg_$reflection_2()]], [["Item", DataSource_$reflection()]], [["Item", Msg_$reflection_3()]], [["Item", Msg_$reflection_4()]], [["Item", Msg_$reflection_5()]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", option_type(DataSourceItem_$reflection())]], [["Item", Msg_$reflection_6()]], [], []]);
}

function hasPreviousPage(state) {
    return state.Page > 1;
}

function hasNextPage(state) {
    return Deferred_exists((pagedData) => ((state.Page * pagedData.PageSize) < pagedData.TotalCount), state.PagedDataDeferred);
}

function buildReportRequest(state) {
    let matchValue_4, matchValue_5;
    let Description;
    const matchValue = state.ExportReportState.ReportDescription;
    Description = ((matchValue != null) ? matchValue : "");
    let OrderStatus;
    const matchValue_1 = state.SelectedOrderStatus;
    OrderStatus = ((matchValue_1 != null) ? (new Int32Array([value_62(Int32_fromString(matchValue_1.Id))])) : (new Int32Array(0)));
    let OrderType;
    const matchValue_2 = state.SelectedOrderType;
    OrderType = ((matchValue_2 != null) ? (new Int32Array([value_62(Int32_fromString(matchValue_2.Id))])) : (new Int32Array(0)));
    return new ReportRequest("OutboundDetails.xlsx", Description, StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(1, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(0, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(3, []))), StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(2, []))), OrderStatus, OrderType, [], new Int32Array([]), [], StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(7, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(8, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(9, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(10, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(12, []))), [], StateSelectFilterHelper_selectedItemsIds(state.SelectFilters, toString(new SearchFilterKey(14, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(15, []))), StateSelectFilterHelper_selectedItemsDisplayValues(state.SelectFilters, toString(new SearchFilterKey(16, []))), "", "None", "None", (state.DateRangeType === 0) ? defaultOf() : MultiDateRangeTypeModule_toInt(state.DateRangeType), "", "", (matchValue_4 = state.DateStart, (matchValue_4 != null) ? matchValue_4 : defaultOf()), (matchValue_5 = state.DateEnd, (matchValue_5 != null) ? matchValue_5 : defaultOf()), state.Page);
}

function hasFilters(state) {
    const request = buildReportRequest(state);
    if (((((((((((((((state.ShowProjectSearch && !(request.ProjectIds.length === 0)) ? true : !(request.AccountIds.length === 0)) ? true : !(request.WarehouseIds.length === 0)) ? true : !(request.MaterialIds.length === 0)) ? true : !(request.OrderStatus.length === 0)) ? true : !(request.OrderType.length === 0)) ? true : !equals(request.DateType, defaultOf())) ? true : !(request.SupplierSKUIds.length === 0)) ? true : !(request.AWSSKUIds.length === 0)) ? true : !(request.WMSOrderIds.length === 0)) ? true : !(request.ExternalASNIds.length === 0)) ? true : !(request.ASNReferenceIds.length === 0)) ? true : !(request.BuyerPOIds.length === 0)) ? true : !(request.ExternalOrderIds.length === 0)) ? true : !(request.PalletIds.length === 0)) {
        return true;
    }
    else {
        return !(request.CartonIds.length === 0);
    }
}

export function convertStateToMultiSearch(typeHeldState) {
    return TypeStateHeldModule_toMultiSearchState(typeHeldState);
}

export function convertStateToTypeAheadSearch(typeHeldState) {
    return TypeStateHeldModule_toTypeAheadSearchState(typeHeldState);
}

export function convertStateToDropDown(typeHeldState) {
    return TypeStateHeldModule_toDropDownSearch(typeHeldState);
}

export function init(user) {
    const patternInput = init_1(new SearchFilterKey(1, []), (searchCriteria) => getProjects(user, searchCriteria));
    const patternInput_1 = init_1(new SearchFilterKey(0, []), (searchCriteria_1) => getAccounts(user, searchCriteria_1));
    const patternInput_2 = init_1(new SearchFilterKey(3, []), (searchCriteria_2) => getWarehouses(user, searchCriteria_2));
    const patternInput_3 = init_1(new SearchFilterKey(2, []), (searchCriteria_3) => getMaterials(user, searchCriteria_3));
    const patternInput_4 = init_1(new SearchFilterKey(15, []), (searchCriteria_4) => getExternalOrderNos(user, searchCriteria_4));
    const patternInput_5 = init_2(toString(new SearchFilterKey(14, [])), 20);
    const patternInput_6 = init_2(toString(new SearchFilterKey(7, [])), 6);
    const patternInput_7 = init_2(toString(new SearchFilterKey(8, [])), 9);
    const patternInput_8 = init_2(toString(new SearchFilterKey(12, [])), 22);
    const patternInput_9 = init_2(toString(new SearchFilterKey(16, [])), 18);
    const patternInput_10 = init_3("Date Range", true);
    const dateFilterState = patternInput_10[0];
    const patternInput_11 = init_4(new OrderTypeInboundOrOutbound(1, []));
    const patternInput_12 = init_1(new SearchFilterKey(9, []), (searchCriteria_5) => getAWSSKUs(user, searchCriteria_5));
    const patternInput_13 = init_2(toString(new SearchFilterKey(10, [])), 15);
    const selectFilters = [];
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(1, []), patternInput[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(0, []), patternInput_1[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(3, []), patternInput_2[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(2, []), patternInput_3[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(9, []), patternInput_12[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(10, []), patternInput_13[0])));
    void (selectFilters.push(StateSelectFilterModule_addMultiSearchFilter(new SearchFilterKey(15, []), patternInput_4[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(12, []), patternInput_8[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(14, []), patternInput_5[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(16, []), patternInput_9[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(7, []), patternInput_6[0])));
    void (selectFilters.push(StateSelectFilterModule_addTypeAheadSearchFilter(new SearchFilterKey(8, []), patternInput_7[0])));
    const patternInput_14 = init_5("Export");
    return [new State(new Deferred$1(0, []), new Deferred$1(0, []), ofArray(selectFilters.slice()), !(user.Role === 3), void 0, void 0, dateFilterState, dateFilterState.DateRangeTypeSelection, dateFilterState.DateStart, dateFilterState.DateEnd, patternInput_11[0], patternInput_14[0], 1), Cmd_batch(ofArray([Cmd_map((Item) => (new Msg(2, [Item])), patternInput[1]), Cmd_map((Item_1) => (new Msg(2, [Item_1])), patternInput_1[1]), Cmd_map((Item_2) => (new Msg(2, [Item_2])), patternInput_2[1]), Cmd_map((Item_3) => (new Msg(2, [Item_3])), patternInput_3[1]), Cmd_map((Item_4) => (new Msg(6, [Item_4])), patternInput_10[1]), Cmd_map((Item_5) => (new Msg(7, [Item_5])), patternInput_11[1]), Cmd_map((Item_6) => (new Msg(2, [Item_6])), patternInput_4[1]), Cmd_map((Item_7) => (new Msg(2, [Item_7])), patternInput_6[1]), Cmd_map((Item_8) => (new Msg(2, [Item_8])), patternInput_7[1]), Cmd_map((Item_9) => (new Msg(2, [Item_9])), patternInput_12[1]), Cmd_map((Item_10) => (new Msg(2, [Item_10])), patternInput_13[1]), patternInput_14[1]]))];
}

export function update(msg, state) {
    let input_3;
    switch (msg.tag) {
        case 13:
            if (hasPreviousPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page - 1), singleton((dispatch_1) => {
                    dispatch_1(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
        case 0:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2: {
                    let command_1;
                    const input_1 = msg.fields[0].fields[0];
                    command_1 = ((input_1.tag === 1) ? Alert_error(Alert_title("Outbound Details Query", Alert_texts(ofArray(input_1.fields[0].Messages)))) : Cmd_none());
                    return [new State(new Deferred$1(2, [(input_3 = msg.fields[0].fields[0], (input_3.tag === 1) ? Default_pagedData() : input_3.fields[0])]), state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), command_1];
                }
                default:
                    if (equals(state.PagedDataDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [new State(new Deferred$1(0, []), state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_none()];
                    }
                    else {
                        return [new State(new Deferred$1(1, []), state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_OfAsyncWith_perform((computation) => {
                            startImmediate(computation);
                        }, getOutboundDetails, buildReportRequest(state), (arg) => (new Msg(0, [new AsyncMsg$1(2, [arg])])))];
                    }
            }
        case 1:
            switch (msg.fields[0].tag) {
                case 1:
                    return [state, Cmd_none()];
                case 2:
                    return [new State(state.PagedDataDeferred, new Deferred$1(2, [void 0]), state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Submitted")))];
                default:
                    if (equals(state.ExportDeferred, new Deferred$1(1, []))) {
                        return [state, Cmd_none()];
                    }
                    else if (!hasFilters(state)) {
                        return [state, Cmd_none()];
                    }
                    else {
                        return [new State(state.PagedDataDeferred, new Deferred$1(1, []), state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_OfAsyncWith_perform((computation_1) => {
                            startImmediate(computation_1);
                        }, exportOutboundDetails, buildReportRequest(state), (arg_2) => (new Msg(1, [new AsyncMsg$1(2, [arg_2])])))];
                    }
            }
        case 2: {
            const filterId = extractName(msg.fields[0]);
            const multiSearchFilter = StateSelectFilterHelper_extractMultiSelectFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId));
            if (multiSearchFilter != null) {
                const patternInput = update_1(msg.fields[0], multiSearchFilter);
                const componentIntent = patternInput[2];
                const componentCmd = patternInput[1];
                const command_4 = (componentIntent.tag === 1) ? Cmd_map((Item_5) => (new Msg(2, [Item_5])), componentCmd) : Cmd_batch(ofArray([Cmd_map((Item_4) => (new Msg(2, [Item_4])), componentCmd), singleton((dispatch_2) => {
                    dispatch_2(new Msg(3, [componentIntent.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_3) => {
                    if (toString(x_3.Id) === filterId) {
                        return new StateSelectFilter(x_3.Id, new TypeStateHeld(0, [patternInput[0]]), x_3.SelectedItems);
                    }
                    else {
                        return x_3;
                    }
                }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), command_4];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_5) => {
                if (toString(x_5.Id) === msg.fields[0].Name) {
                    return new StateSelectFilter(x_5.Id, x_5.State, msg.fields[0].Items);
                }
                else {
                    return x_5;
                }
            }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, isEmpty(msg.fields[0].Items) ? state.Page : 1), singleton((dispatch_3) => {
                dispatch_3(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 4: {
            const filterId_1 = extractName_1(msg.fields[0]);
            const typeAheadFilter = StateSelectFilterHelper_extractTypeAheadFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_1));
            if (typeAheadFilter != null) {
                const patternInput_1 = update_2(msg.fields[0], typeAheadFilter);
                const componentIntent_1 = patternInput_1[2];
                const componentCmd_1 = patternInput_1[1];
                const command_5 = (componentIntent_1.tag === 1) ? Cmd_map((Item_7) => (new Msg(4, [Item_7])), componentCmd_1) : Cmd_batch(ofArray([Cmd_map((Item_6) => (new Msg(4, [Item_6])), componentCmd_1), singleton((dispatch_4) => {
                    dispatch_4(new Msg(5, [componentIntent_1.fields[0]]));
                })]));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_6) => {
                    if (toString(x_6.Id) === filterId_1) {
                        return new StateSelectFilter(x_6.Id, new TypeStateHeld(1, [patternInput_1[0]]), x_6.SelectedItems);
                    }
                    else {
                        return x_6;
                    }
                }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), command_5];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 5:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_8) => {
                if (toString(x_8.Id) === msg.fields[0].SearchDataId) {
                    return new StateSelectFilter(x_8.Id, x_8.State, ofArray(msg.fields[0].Data));
                }
                else {
                    return x_8;
                }
            }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, isEmpty(ofArray(msg.fields[0].Data)) ? state.Page : 1), singleton((dispatch_5) => {
                dispatch_5(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 6: {
            const patternInput_2 = update_3(msg.fields[0], state.DateFilter);
            const componentState_2 = patternInput_2[0];
            const componentIntent_2 = patternInput_2[2];
            if (componentIntent_2.tag === 1) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, componentState_2, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), Cmd_none()];
            }
            else {
                const data = componentIntent_2.fields[0];
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, componentState_2, data.DateRangeType, data.DateStart, data.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), singleton((dispatch_6) => {
                    dispatch_6(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
        }
        case 7: {
            const patternInput_3 = update_4(msg.fields[0], state.OrderTypeStatus);
            const componentIntent_3 = patternInput_3[2];
            const componentCmd_3 = patternInput_3[1];
            return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, patternInput_3[0], state.ExportReportState, state.Page), (componentIntent_3.tag === 1) ? Cmd_batch(ofArray([Cmd_map((Item_9) => (new Msg(7, [Item_9])), componentCmd_3), singleton((dispatch_8) => {
                dispatch_8(new Msg(10, [componentIntent_3.fields[0]]));
            })])) : ((componentIntent_3.tag === 2) ? Cmd_map((Item_10) => (new Msg(7, [Item_10])), componentCmd_3) : Cmd_batch(ofArray([Cmd_map((Item_8) => (new Msg(7, [Item_8])), componentCmd_3), singleton((dispatch_7) => {
                dispatch_7(new Msg(9, [componentIntent_3.fields[0]]));
            })])))];
        }
        case 9:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, msg.fields[0], state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, 1), singleton((dispatch_9) => {
                dispatch_9(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 10:
            return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, msg.fields[0], state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, 1), singleton((dispatch_10) => {
                dispatch_10(new Msg(0, [new AsyncMsg$1(0, [])]));
            })];
        case 11:
            if (!hasFilters(state)) {
                return [state, Toast_info(Toast_title("Invalid Option", Toast_text("Cannot export a report without filters")))];
            }
            else {
                const patternInput_4 = update_5(msg.fields[0], state.ExportReportState);
                const componentState_4 = patternInput_4[0];
                const componentIntent_4 = patternInput_4[2];
                switch (componentIntent_4.tag) {
                    case 1:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_4, state.Page), Toast_info(Toast_title("Report Creation Status", Toast_text("Report Export Cancelled")))];
                    case 2:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_4, state.Page), Cmd_none()];
                    default:
                        return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, componentState_4, state.Page), singleton((dispatch_11) => {
                            dispatch_11(new Msg(1, [new AsyncMsg$1(0, [])]));
                        })];
                }
            }
        case 8: {
            const filterId_2 = extractName_2(msg.fields[0]);
            const dropDownFilter = StateSelectFilterHelper_extractDropDownFilter(StateSelectFilterHelper_tryGet(state.SelectFilters, filterId_2));
            if (dropDownFilter != null) {
                const patternInput_5 = update_6(msg.fields[0], dropDownFilter);
                const componentIntent_5 = patternInput_5[2];
                const componentCmd_5 = patternInput_5[1];
                const command_9 = (componentIntent_5.tag === 1) ? Cmd_map((Item_12) => (new Msg(8, [Item_12])), componentCmd_5) : ((componentIntent_5.tag === 2) ? Cmd_map((Item_13) => (new Msg(8, [Item_13])), componentCmd_5) : Cmd_batch(ofArray([Cmd_map((Item_11) => (new Msg(8, [Item_11])), componentCmd_5), singleton((dispatch_12) => {
                    dispatch_12(new Msg(0, [new AsyncMsg$1(0, [])]));
                })])));
                return [new State(state.PagedDataDeferred, state.ExportDeferred, map((x_9) => {
                    if (toString(x_9.Id) === filterId_2) {
                        return new StateSelectFilter(x_9.Id, new TypeStateHeld(2, [patternInput_5[0]]), x_9.SelectedItems);
                    }
                    else {
                        return x_9;
                    }
                }, state.SelectFilters), state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page), command_9];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        default:
            if (hasNextPage(state)) {
                return [new State(state.PagedDataDeferred, state.ExportDeferred, state.SelectFilters, state.ShowProjectSearch, state.SelectedOrderStatus, state.SelectedOrderType, state.DateFilter, state.DateRangeType, state.DateStart, state.DateEnd, state.OrderTypeStatus, state.ExportReportState, state.Page + 1), singleton((dispatch) => {
                    dispatch(new Msg(0, [new AsyncMsg$1(0, [])]));
                })];
            }
            else {
                return [state, Cmd_none()];
            }
    }
}

export function renderRow1(state, dispatch) {
    let elems_11, elems_10, elems_9;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_10 = [createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = toList(delay(() => {
        let elems;
        return append(state.ShowProjectSearch ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
            dispatch(new Msg(2, [arg]));
        }), new SearchFilterKey(1, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty(), delay(() => {
            let elems_1;
            return append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_1(convertStateToMultiSearch(filter_1.State), (arg_1) => {
                dispatch(new Msg(2, [arg_1]));
            }), new SearchFilterKey(0, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                let elems_8, elems_7, elems_3, elems_2, elems_5, elems_4, elems_6;
                return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-right"])], (elems_7 = [createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small"])], ["type", "button"], ["disabled", !hasPreviousPage(state) ? true : !hasFilters(state)], ["onClick", (_arg) => {
                    dispatch(new Msg(13, []));
                }], (elems_3 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_2 = [createElement("span", {
                    children: ["navigate_before"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Previous",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("button", createObj(ofArray([["className", join(" ", ["button", "is-small", "ml-2"])], ["type", "button"], ["disabled", !hasNextPage(state) ? true : !hasFilters(state)], ["onClick", (_arg_1) => {
                    dispatch(new Msg(12, []));
                }], (elems_5 = [createElement("span", createObj(ofArray([["className", join(" ", ["material-icons", "is-small"])], (elems_4 = [createElement("span", {
                    children: ["navigate_next"],
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("span", {
                    className: join(" ", ["is-sr-only"]),
                    children: "Next",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("div", createObj(singleton((elems_6 = [render_2(state.ExportReportState, (arg_2) => {
                    dispatch(new Msg(11, [arg_2]));
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
}

export function renderRow2(state, dispatch) {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems = [render_3(state.DateFilter, (arg) => {
        dispatch(new Msg(6, [arg]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [render_4(state.OrderTypeStatus, (arg_1) => {
        dispatch(new Msg(7, [arg_1]));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function renderRow3(state, dispatch) {
    let elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(2, [arg]));
    }), new SearchFilterKey(3, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_5(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(4, [arg_1]));
    }), new SearchFilterKey(14, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_1(convertStateToMultiSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(2, [arg_2]));
    }), new SearchFilterKey(15, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderRow4(state, dispatch) {
    let elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_5(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(4, [arg]));
    }), new SearchFilterKey(7, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_5(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(4, [arg_1]));
    }), new SearchFilterKey(8, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_5(convertStateToTypeAheadSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(4, [arg_2]));
    }), new SearchFilterKey(12, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderRow5(state, dispatch) {
    let elems_3, elems, elems_1, elems_2;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_5(convertStateToTypeAheadSearch(filter.State), (arg) => {
        dispatch(new Msg(4, [arg]));
    }), new SearchFilterKey(16, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_1 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_1) => render_5(convertStateToTypeAheadSearch(filter_1.State), (arg_1) => {
        dispatch(new Msg(4, [arg_1]));
    }), new SearchFilterKey(10, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_2 = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter_2) => render_1(convertStateToMultiSearch(filter_2.State), (arg_2) => {
        dispatch(new Msg(2, [arg_2]));
    }), new SearchFilterKey(9, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

export function renderRow6(state, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [StateSelectFilterHelper_renderSearch(state.SelectFilters, (filter) => render_1(convertStateToMultiSearch(filter.State), (arg) => {
        dispatch(new Msg(2, [arg]));
    }), new SearchFilterKey(2, []))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function render(state, dispatch) {
    let elems_11, elems_1, elems, elems_4, elems_3, elems_2, matchValue, pagedData, elems_10;
    return createElement("div", createObj(singleton((elems_11 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = [createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", {
        className: join(" ", ["column"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["column", "has-text-right"])], (elems = [createElement("a", {
        className: join(" ", ["has-link"]),
        href: "#/scheduled-reports",
        children: "Scheduled Reports",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "box"], (elems_4 = [createElement("h1", {
        className: join(" ", ["has-text-link", "has-text-weight-semibold"]),
        children: "Outbound Details Query",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-flex", "is-align-items-center"])], (elems_2 = [createElement("h1", {
        className: join(" ", ["has-text-link"]),
        children: (matchValue = state.PagedDataDeferred, (matchValue.tag === 1) ? "Searching..." : ((matchValue.tag === 2) ? ((pagedData = matchValue.fields[0], `${pagedData.TotalCount} items found | showing page ${state.Page} of ${pagedData.NumberOfPages}`)) : "")),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), renderRow1(state, dispatch), renderRow2(state, dispatch), renderRow3(state, dispatch), renderRow4(state, dispatch), renderRow5(state, dispatch), renderRow6(state, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_10 = toList(delay(() => {
        let elems_9, elems_8, elems_5, children, elems_7;
        const matchValue_1 = state.PagedDataDeferred;
        switch (matchValue_1.tag) {
            case 1:
                return singleton_1(render_6());
            case 2: {
                const pagedData_1 = matchValue_1.fields[0];
                if (pagedData_1.TotalCount === 0) {
                    return singleton_1(createElement("div", {
                        className: join(" ", ["has-text-centered"]),
                        children: "No items were found that meet the search criteria",
                    }));
                }
                else {
                    const tableHeader = (header) => createElement("th", {
                        className: join(" ", ["px-3", "py-2", "has-text-link"]),
                        children: header,
                    });
                    const tableData = (text) => createElement("td", {
                        className: join(" ", ["px-3", "py-2"]),
                        children: text,
                    });
                    return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container"])], (elems_9 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_8 = [createElement("thead", createObj(singleton((elems_5 = [(children = ofArray([tableHeader("WMS Order #"), tableHeader("External Order #"), tableHeader("External Line #"), tableHeader("Order Add Date"), tableHeader("Actual Ship Date"), tableHeader("Priority"), tableHeader("Order Type"), tableHeader("Order Status"), tableHeader("ODM ID"), tableHeader("ODM Name"), tableHeader("Supplier ID"), tableHeader("Supplier Name"), tableHeader("AWS SKU"), tableHeader("Supplier SKU"), tableHeader("SKU Description"), tableHeader("Quantity"), tableHeader("Carton ID"), tableHeader("Pallet ID"), tableHeader("Batch"), tableHeader("ASN Reference"), tableHeader("Packing List #"), tableHeader("Reservation Add Time"), tableHeader("Reservation Order"), tableHeader("Buyer PO"), tableHeader("Buyer OLPO"), tableHeader("Quantity Ordered"), tableHeader("COO"), tableHeader("Quantity Allocated"), tableHeader("Need by Date"), tableHeader("Pick Up Date")]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])))), createElement("tbody", createObj(singleton((elems_7 = toList(delay(() => map_1((item) => {
                        let elems_6;
                        return createElement("tr", createObj(singleton((elems_6 = [tableData(formatInt(item.WmsOrderNo)), tableData(item.ExternalOrderNo), tableData(formatInt(item.ExternalLineNo)), tableData(formatDateTime(item.OrderAddDate)), tableData(formatDateTime(item.ActualShipDate)), tableData(item.Priority), tableData(item.OrderType), tableData(item.OrderStatus), tableData(item.OdmId), tableData(item.OdmName), tableData(item.SupplierId), tableData(item.SupplierName), tableData(item.AwsSku), tableData(item.SupplierSku), tableData(item.SkuDescription), tableData(formatDecimal(item.Quantity)), tableData(item.CartonId), tableData(item.PalletId), tableData(item.Batch), tableData(item.AsnReference), tableData(item.PackingListNo), tableData(formatDateTime(item.ReservationAddTime)), tableData(item.ReservationOrder), tableData(item.BuyerPo), tableData(item.BuyerOLPO), tableData(formatDecimal(item.QuantityOrdered)), tableData(item.Coo), tableData(formatDecimal(item.QuantityAllocated)), tableData(formatDateTime(item.NeedbyDate)), tableData(formatDateTime(item.PickupDate))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]))));
                    }, pagedData_1.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))));
                }
            }
            default:
                return singleton_1(createElement("div", {
                    className: join(" ", ["has-text-centered"]),
                    children: "Select filters to run the query",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))]))));
}

