import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, enum_type, int32_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { UserResponse_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { intersection } from "../Components/ClaimHelpers.fs.js";
import { chunkBySize, ofArray, singleton as singleton_1, isEmpty } from "../fable_modules/fable-library.4.10.0/List.js";
import { createElement } from "react";
import { map, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { LayoutElement } from "../Models.fs.js";

export class State extends Record {
    constructor(Role, User) {
        super();
        this.Role = (Role | 0);
        this.User = User;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.Pages.Home.State", [], State, () => [["Role", enum_type("FootPrint.Portal.CustomTypes.FootPrintRole", int32_type, [["Administrator", 1], ["ODM", 2], ["Supplier", 3], ["CMODM", 4], ["SuperUser", 5], ["CustomRole", 6], ["Unknown", 7]])], ["User", UserResponse_$reflection()]]);
}

export class Msg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["NoOp"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.Pages.Home.Msg", [], Msg, () => [[]]);
}

export function init(user) {
    return [new State(user.Role, user), Cmd_none()];
}

export function update(msg, state) {
    return [state, Cmd_none()];
}

export function layoutElements(state, dispatch, sectionTitle, elements) {
    const elementsAllowed = intersection(state.User.Claims, elements);
    if (isEmpty(elementsAllowed)) {
        return createElement("span", {});
    }
    else {
        const children = toList(delay(() => append(singleton(createElement("h6", {
            className: join(" ", ["title", "is-6", "has-text-link"]),
            children: sectionTitle,
        })), delay(() => map((row) => {
            let elems_1;
            return createElement("div", createObj(ofArray([["className", "columns"], (elems_1 = toList(delay(() => map((element) => {
                let elems;
                return createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-one-third"])], (elems = [createElement("a", {
                    className: join(" ", ["box", "has-text-centered", "has-text-link", "has-text-weight-semibold"]),
                    href: RouterModule_encodeParts(singleton_1(element.Url), 1),
                    children: element.DisplayText,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
            }, row))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
        }, chunkBySize(3, elementsAllowed))))));
        return createElement("span", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
}

export function renderSuperUserSection(state, dispatch) {
    return layoutElements(state, dispatch, "Super User", singleton_1(new LayoutElement(14, "/assign-user-roles", "Manage Roles")));
}

export function renderAdministratorSection(state, dispatch) {
    return layoutElements(state, dispatch, "Administration", ofArray([new LayoutElement(4, "/account-upload", "Manage Accounts"), new LayoutElement(5, "/materials-upload", "Manage Materials")]));
}

export function renderOrdersSection(state, dispatch) {
    return layoutElements(state, dispatch, "Orders", ofArray([new LayoutElement(1, "/advanced-shipping-notice-upload", "Advanced Shipping Notices"), new LayoutElement(2, "/reservation-upload", "Reservations"), new LayoutElement(3, "/shipping-order-upload", "Shipping Orders")]));
}

export function renderQueriesSection(state, dispatch) {
    return layoutElements(state, dispatch, "Queries", ofArray([new LayoutElement(6, "/inbound-details-query", "Inbound Details"), new LayoutElement(7, "/inbound-summary-query", "Inbound Summary"), new LayoutElement(8, "/on-hand-details-query", "On Hand Details"), new LayoutElement(9, "/outbound-details-query", "Outbound Details"), new LayoutElement(10, "/outbound-summary-query", "Outbound Summary"), new LayoutElement(11, "/reservations-query", "Reservations"), new LayoutElement(12, "/materials-query", "Materials"), new LayoutElement(13, "/shipping-matrix-query", "Shipping Matrix")]));
}

export function render(state, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["container"])], (elems = [renderSuperUserSection(state, dispatch), createElement("br", {}), renderAdministratorSection(state, dispatch), createElement("br", {}), renderOrdersSection(state, dispatch), createElement("br", {}), renderQueriesSection(state, dispatch), createElement("br", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

