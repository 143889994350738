import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { union_type, record_type, option_type, list_type, bool_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { SelectItem, SelectItem_$reflection } from "../../FootPrint.Portal.DTOs/DTOs.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.1.0/cmd.fs.js";
import { ofArray, tryFind, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { value as value_18 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.4.10.0/Util.js";
import { join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/./Interop.fs.js";

export class State extends Record {
    constructor(Name, Disabled, Items, SelectedItem, PlaceholderText) {
        super();
        this.Name = Name;
        this.Disabled = Disabled;
        this.Items = Items;
        this.SelectedItem = SelectedItem;
        this.PlaceholderText = PlaceholderText;
    }
}

export function State_$reflection() {
    return record_type("FootPrint.Portal.UI.DropDownComponent.DropDownComponent.State", [], State, () => [["Name", string_type], ["Disabled", bool_type], ["Items", list_type(SelectItem_$reflection())], ["SelectedItem", option_type(SelectItem_$reflection())], ["PlaceholderText", string_type]]);
}

export class SelectItemData extends Record {
    constructor(Name, SelectedItem) {
        super();
        this.Name = Name;
        this.SelectedItem = SelectedItem;
    }
}

export function SelectItemData_$reflection() {
    return record_type("FootPrint.Portal.UI.DropDownComponent.DropDownComponent.SelectItemData", [], SelectItemData, () => [["Name", string_type], ["SelectedItem", option_type(SelectItem_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectItem", "ClearItems"];
    }
}

export function Msg_$reflection() {
    return union_type("FootPrint.Portal.UI.DropDownComponent.DropDownComponent.Msg", [], Msg, () => [[["Item", SelectItemData_$reflection()]], [["Item", string_type]]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ItemSelected", "ItemsCleared", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("FootPrint.Portal.UI.DropDownComponent.DropDownComponent.Intent", [], Intent, () => [[["Item", option_type(SelectItem_$reflection())]], [], []]);
}

export function init(stateTag, items, placeholderText) {
    return [new State(stateTag, false, items, void 0, placeholderText), Cmd_batch(singleton(singleton((dispatch) => {
        dispatch((Item) => (new Msg(1, [Item])));
    })))];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.Name, state.Disabled, state.Items, void 0, state.PlaceholderText), Cmd_none(), new Intent(1, [])];
    }
    else {
        const selectedItem = tryFind((x) => (x.Id === value_18(msg.fields[0].SelectedItem).Id), state.Items);
        return [new State(state.Name, state.Disabled, state.Items, selectedItem, state.PlaceholderText), Cmd_none(), new Intent(0, [selectedItem])];
    }
}

export function extractName(componentMsg) {
    if (componentMsg.tag === 1) {
        return componentMsg.fields[0];
    }
    else {
        return componentMsg.fields[0].Name;
    }
}

export function render(state, dispatch) {
    let elems_2, elems_1, matchValue, elems;
    return createElement("div", createObj(ofArray([["className", "label-is-small"], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_1 = [createElement("select", createObj(ofArray([["className", join(" ", ["input", "is-small", "is-full-width"])], ["onChange", (ev) => {
        const x = ev.target.value;
        dispatch(new Msg(0, [new SelectItemData(state.Name, new SelectItem(x, x))]));
    }], ["value", (matchValue = state.SelectedItem, (matchValue != null) ? matchValue.Id : "")], (elems = toList(delay(() => append(singleton_1(createElement("option", {
        children: state.PlaceholderText,
        value: 0,
    })), delay(() => map((item) => createElement("option", {
        children: item.SelectValue,
        value: item.Id,
    }), state.Items))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

