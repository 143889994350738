import { ofNullable, map as map_1, defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { isNullOrWhiteSpace, interpolate, toText } from "../fable_modules/fable-library.4.10.0/String.js";
import { year, day, month } from "../fable_modules/fable-library.4.10.0/Date.js";
import { toString } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ofArray, filter, empty } from "../fable_modules/fable-library.4.10.0/List.js";
import { OrderStatusModule_optional } from "../../FootPrint.Portal.DTOs/CustomTypes.fs.js";

export function formatDateTime(value) {
    return defaultArg(map_1((dateTime) => toText(interpolate("%A%P()", [dateTime])), ofNullable(value)), "");
}

export function formatDateTimeAsCanonical(value) {
    const map = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return `${map[month(value) - 1]} ${day(value)}, ${year(value)}`;
}

export function formatInt(value) {
    if (value != null) {
        return toString(value);
    }
    else {
        return "";
    }
}

export function formatDecimal(value) {
    if (value != null) {
        return toString(value);
    }
    else {
        return "";
    }
}

export function formatBillToAddress(model) {
    if (isNullOrWhiteSpace(model.BillToCompany)) {
        return empty();
    }
    else {
        return filter((arg) => !isNullOrWhiteSpace(arg), ofArray([model.BillToCompany, model.BillToAddress1, model.BillToAddress2, `${model.BillToCity} ${model.BillToState}, ${model.BillToZip} ${model.BillToCountry}`]));
    }
}

export function formatShipToAddress(model) {
    if (isNullOrWhiteSpace(model.ShipToCompany)) {
        return empty();
    }
    else {
        return filter((arg) => !isNullOrWhiteSpace(arg), ofArray([model.ShipToCompany, model.ShipToAddress1, model.ShipToAddress2, `${model.ShipToCity} ${model.ShipToState}, ${model.ShipToZip} ${model.ShipToCountry}`]));
    }
}

export function formatOrderStatusAsClass(orderStatusName) {
    const matchValue = OrderStatusModule_optional(orderStatusName);
    if (matchValue == null) {
        return "";
    }
    else {
        switch (matchValue.tag) {
            case 1:
                return "is-link";
            case 2:
                return "is-danger";
            case 3:
                return "is-success";
            default:
                return "is-info";
        }
    }
}

export function formatStatusDescriptionAsClass(orderStatusName, statusDescription) {
    if (isNullOrWhiteSpace(statusDescription)) {
        return "";
    }
    else {
        const matchValue = OrderStatusModule_optional(orderStatusName);
        if (matchValue == null) {
            return "";
        }
        else {
            switch (matchValue.tag) {
                case 1:
                    return "has-text-link";
                case 2:
                    return "has-text-danger";
                case 3:
                    return "has-text-success";
                default:
                    return "has-text-info";
            }
        }
    }
}

export function formatErrorCodeAsClass(errorDescription) {
    if (errorDescription === "") {
        return "is-success";
    }
    else {
        return "is-danger";
    }
}

export function formatErrorCode(errorCode, errorDescription) {
    if (errorDescription === "") {
        return "None";
    }
    else {
        return errorCode;
    }
}

